import React, { useState, useEffect, useCallback } from "react";
import { Grid, Box, Typography, TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Chart from "react-apexcharts";
import { getRequestInstanceOne } from "../../../apiServices/apiCall";

const chartOptions = {
  chart: {
    type: "donut",
    height: "100%",
    width: "100%",
  },
  colors: ["#1E3A8A", "#D97706"],
  labels: ["KG", "PKTS"],
  legend: {
    show: true,
    position: "bottom",
    fontSize: "16px",
    formatter: function (val, opts) {
      const label = opts.w.globals.labels[opts.seriesIndex];
      const series = opts.w.globals.series[opts.seriesIndex];
      return `${label}: ${series}`;
    },
  },
  fill: {
    colors: ["#1E3A8A", "#D97706"],
  },
  tooltip: {
    y: {
      formatter: function (val) {
        return val;
      },
      style: {
        color: "#5052ba",
      },
    },
  },
};

const KgDonutChart = ({
  selectedSalesgroup,
  selectedStockist,
  selectedNames,
  customDateRange,
}) => {
  const [chartData, setChartData] = useState({
    series: [],
    options: chartOptions,
  });
  const [items, setItems] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState("All");

  const fetchData = useCallback(async () => {
    try {
      const params = new URLSearchParams();
      if (customDateRange) {
        params.append("start_date", customDateRange.start_date);
        params.append("end_date", customDateRange.end_date);
      }
      if (selectedSalesgroup) {
        params.append("salesgroup", selectedSalesgroup);
      }
      if (selectedStockist && localStorage.getItem("user") === "admin") {
        params.append("stockist", selectedStockist);
      }
      if (localStorage.getItem("user") !== "admin") {
        params.append("user", localStorage.getItem("user"));
      }
      if (selectedNames) {
        params.append("name", selectedNames);
      }
      const url = `/api/datewise_pkt_kg_counts?${params.toString()}`;
      const response = await getRequestInstanceOne(url);
      console.log("response: ===", response);

      // Extract item IDs and label them for Autocomplete
      const itemIds = response.data[0].items.map((item) => ({
        itemId: item._id,
        Kg: item.Kg,
        Pkts: item.Pkts,
      }));

      // Add 'All' option to itemIds
      itemIds.unshift({
        itemId: "All",
        Kg: response.data[0].TotalKg,
        Pkts: response.data[0].TotalPkts,
      });

      setItems(itemIds);
      setSelectedFilter("All");
      if (itemIds.length > 0) {
        const firstItem = itemIds[0];

        // Check if Kg and Pkts are valid (not null or empty)
        const hasData = firstItem.Kg || firstItem.Pkts;

        if (hasData) {
          setChartData({
            series: [firstItem.Kg || 0, firstItem.Pkts || 0], // Handle cases where either might be missing
            options: {
              ...chartOptions,
              legend: {
                ...chartOptions.legend,
                formatter: function (val, opts) {
                  const label = opts.w.globals.labels[opts.seriesIndex];
                  const series = opts.w.globals.series[opts.seriesIndex];
                  return `${label}: ${series}`;
                },
              },
            },
          });
        } else {
          // If both Kg and Pkts are empty, show "No data available"
          setChartData({
            series: [], // No series data
            options: {
              ...chartOptions,
              title: {
                text: "No data available",
                align: "center",
                style: {
                  fontSize: "14px",
                  color: "#666",
                },
              },
            },
          });
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, [customDateRange, selectedSalesgroup, selectedStockist, selectedNames]);

  useEffect(() => {
    fetchData();

    // // Set up polling to fetch data every minute
    // const intervalId = setInterval(() => {
    //     fetchData();
    // }, 60000); // 60 seconds

    // // Clean up the interval on component unmount
    // return () => clearInterval(intervalId);
  }, [fetchData]);

  const handleItemIdChange = (event, newValue) => {
    setSelectedFilter(newValue ? newValue.itemId : "All"); // Update selected item ID

    if (newValue) {
      setChartData({
        series: [newValue.Kg, newValue.Pkts],
        options: {
          ...chartOptions,
          legend: {
            ...chartOptions.legend,
            formatter: function (val, opts) {
              const label = opts.w.globals.labels[opts.seriesIndex];
              const series = opts.w.globals.series[opts.seriesIndex];
              return `${label}: ${series}`;
            },
          },
        },
      });
    } else {
      setChartData({
        series: [],
        options: chartOptions,
      });
    }
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
        >
          <Typography variant="h6">KG and PKTS by Brands</Typography>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Autocomplete
          size="small"
          id="itemId-select"
          options={items}
          getOptionLabel={(option) => option.itemId}
          value={items.find((item) => item.itemId === selectedFilter) || null}
          onChange={handleItemIdChange}
          disableClearable // This will disable the clear (cross) icon
          renderInput={(params) => (
            <TextField {...params} label="Select Item ID" variant="outlined" />
          )}
          sx={{ mb: 2 }}
        />
      </Grid>

      <Grid item xs={12}>
        {chartData.series.length > 0 ? (
          <Chart
            options={chartData.options}
            series={chartData.series}
            type="donut"
            height="350"
          />
        ) : (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="350px"
          >
            <Typography variant="h6" align="center">
              No data available for the selected date range.
            </Typography>
          </Box>
        )}
      </Grid>
    </Grid>
  );
};

export default KgDonutChart;
