import React from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import AccountCircle from "@mui/icons-material/AccountCircle";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import DrawerList from "./DrawerList";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  backgroundColor: "#fff",
  color: "#000",
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": { ...openedMixin(theme), backgroundColor: "#292731" },
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": { ...closedMixin(theme), backgroundColor: "#292731" },
  }),
}));

const AppBarWithDrawer = () => {
  const user = localStorage.getItem("token");
  const navigate = useNavigate(); // Hook to navigate programmatically
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    navigate("/login"); // Navigate to login page after logout
  };

  // const handleReload = () => {
  //   window.location.reload();
  // };

  return (
    <Box sx={{ display: "flex", width: "100%" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={() => setOpen((open) => !open)}
            edge="start"
            sx={{
              marginRight: 2,
            }}
          >
            {!open ? <MenuIcon /> : null}
          </IconButton>
          <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
            Order Management
          </Typography>
          {/* <Button
            variant="outlined"
            color="inherit"
            onClick={handleReload}
          >
            Refresh
          </Button> */}
          <Typography
            variant="body1"
            noWrap
            sx={{
              display: { xs: "none", sm: "block" },
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
              color: "white",
              backgroundColor: "#5052ba", // Add background color
              padding: "5px 8px", // Add padding for spacing
              borderRadius: "5px", // Rounded corners for a smoother look
              transition: "background-color 0.3s ease", // Add smooth hover effect
              "&:hover": {
                backgroundColor: "black", // Change background on hover
              },
            }}
            onClick={handleMenu}
          >
            <AccountCircle sx={{ mr: 1, color: "honeydew" }} />
            {user.toLocaleLowerCase()}
            {Boolean(anchorEl) ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
          </Typography>

          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "bottom",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={handleLogout} sx={{ color: "darkcyan" }}>
              <ExitToAppIcon sx={{ mr: 1, color: "darkcyan" }} /> Logout
            </MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <Typography
            variant="h5"
            sx={{ color: "#fff", ml: 2, fontWeight: "bold" }}
          >
            Sapat
          </Typography>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={() => setOpen((open) => !open)}
            edge="start"
            sx={{
              color: "#fff",
            }}
          >
            {open ? <CloseIcon /> : <MenuIcon />}
          </IconButton>
        </DrawerHeader>
        <DrawerList open={open} navigate={navigate} />
      </Drawer>
      <Box component="main" sx={{ overflowX: "auto", flexGrow: 1, p: 2 }}>
        <DrawerHeader />
        <Outlet />
      </Box>
    </Box>
  );
};

export default AppBarWithDrawer;
