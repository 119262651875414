import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedFilter: "Today",
  customDateRange: {
    start_date: new Date().toISOString().split("T")[0],
    end_date: new Date().toISOString().split("T")[0],
  },
};

export const allOrderDateFilterSlice = createSlice({
  name: "allOrderDateFilter",
  initialState,
  reducers: {
    updateSelectedFilterAllOrder(state, action) {
      state.selectedFilter = action.payload;
    },
    updateCustomDateRangeAllOrder(state, action) {
      state.customDateRange.start_date = action.payload.start_date;
      state.customDateRange.end_date = action.payload.end_date;
    }
  },
});

export const { updateSelectedFilterAllOrder, updateCustomDateRangeAllOrder } =
allOrderDateFilterSlice.actions;
export const allOrderDateFilterReducer = allOrderDateFilterSlice.reducer;

export const categoriesDateFilterSlice = createSlice({
  name: "categoriesDateFilter",
  initialState,
  reducers: {
    updateSelectedFilterCategories(state, action) {
      state.selectedFilter = action.payload;
    },
    updateCustomDateRangeCategories(state, action) {
      state.customDateRange.start_date = action.payload.start_date;
      state.customDateRange.end_date = action.payload.end_date;
    }
  },
});

export const { updateSelectedFilterCategories, updateCustomDateRangeCategories } =
categoriesDateFilterSlice.actions;
export const categoriesDateFilterReducer = categoriesDateFilterSlice.reducer;