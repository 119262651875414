import React, { memo, useCallback, useState } from "react";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import CustomDialog from "../../../components/CustomDialog/CustomDialog";
import GroupByData from "../GroupByData/GroupByData";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: "#d3d4ee",
  color: theme.palette.common.black,
  padding: "12px",
  textAlign: "center",
  position: "sticky",
  top: 0,
  zIndex: 1,
  fontWeight: 600,
  fontFamily: "Arial, sans-serif",
  fontSize: "14px", // Default font size for desktop
  [theme.breakpoints.down("sm")]: {
    fontSize: "12px", // Adjust font size for smaller screens
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.background.default,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
  "& td, & th": {
    padding: 5,
  },
}));

const OrderBy = memo(({ groupByData, customDateRange }) => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [selectedGroupBy, setSelectedGroupBy] = useState("");

  const handleRowClick = useCallback(
    (group) => {
      setSelectedGroupBy(group);
      setOpenDrawer(true);
    },
    [setSelectedGroupBy, setOpenDrawer]
  );

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <TableContainer sx={{ maxHeight: 400, overflow: "auto" }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <StyledTableCell>OrderBy</StyledTableCell>
                <StyledTableCell>OrderBy ID</StyledTableCell>
                <StyledTableCell>TC</StyledTableCell>
                <StyledTableCell>PC</StyledTableCell>
                <StyledTableCell>KG Sales</StyledTableCell>
                <StyledTableCell>Pkt Sales</StyledTableCell>
                <StyledTableCell>Working Days</StyledTableCell>
                <StyledTableCell>Action</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {groupByData.map((row, ind) => (
                <StyledTableRow key={row.orderby_id}>
                  <TableCell align="center">{row.orderby}</TableCell>
                  <TableCell align="center">{row.orderby_id || "-"}</TableCell>
                  <TableCell align="center">{row.tc || "-"}</TableCell>
                  <TableCell align="center">{row.pc || "-"}</TableCell>
                  <TableCell align="center">{row.kg_sales || "-"}</TableCell>
                  <TableCell align="center">{row.pkt_sales || "-"}</TableCell>
                  <TableCell align="center">{row.working_days || 0}</TableCell>
                  <TableCell align="center">
                    <Button
                      variant="outlined"
                      size="small"
                      color="success"
                      onClick={() => handleRowClick(row, ind)}
                    >
                      View
                    </Button>
                  </TableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <CustomDialog
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        title={`Order By - ${selectedGroupBy.orderby}`}
        fullscreen={true} // Set to true if you want the dialog to be fullscreen
        maxWidth="md" // Set the maxWidth as needed
      >
        <GroupByData
          customDateRange={customDateRange}
          selectedGroup={selectedGroupBy.orderby}
          tc={selectedGroupBy.tc}
          pc={selectedGroupBy.pc}
          groupBy="orderby"
          groupData={groupByData}
        />
      </CustomDialog>
    </Box>
  );
});

export default OrderBy;
