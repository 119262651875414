import React from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Grid,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import * as XLSX from "xlsx";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFViewer,
  PDFDownloadLink,
} from "@react-pdf/renderer";

const CustomDialog = ({
  open,
  onClose,
  title,
  children,
  fullscreen = false,
  maxWidth = "xs",
  MyBgColor,
  PDFData,
  ExcelData,
  ViewDATA,
  Discounted_Amount,
}) => {
  // Sample styles
  const styles = StyleSheet.create({
    page: { padding: 20 },
    header: { fontSize: 18, textAlign: "center", marginBottom: 10 },
    row: {
      flexDirection: "row",
      justifyContent: "space-between",
      marginBottom: 5,
    },
    columnLeft: {
      width: "70%",
      fontSize: 10,
      lineHeight: 1.3,
      fontWeight: "bold",
    },
    columnRight: {
      width: "30%",
      fontSize: 10,
      lineHeight: 1.3,
      textAlign: "right",
    },

    table: {
      marginTop: 10,
      borderWidth: 1,
      borderColor: "grey",
      borderCollapse: "collapse",
    },
    tableRow: {
      flexDirection: "row",
      borderBottomWidth: 1,
      borderBottomColor: "#000",
    },
    tableCol: {
      borderRightWidth: 1,
      borderRightColor: "#000",
      justifyContent: "center",
      padding: 5,
    },
    tableCell: { fontSize: 10, textAlign: "center" },
    footer: { fontSize: 10, marginTop: 10 },
    footerNote: { fontSize: 10, textAlign: "center", marginTop: 5 },
    declaration: {
      fontSize: 10,
      marginTop: 20,
      textAlign: "center",
      fontStyle: "italic",
    },
  });

  // pdf

  // PDF Document Component
  const InvoiceDocument = () => (
    <Document>
      <Page size="A4" style={styles.page}>
        {/* Header Section */}
        <Text style={styles.header}>Tax Invoice (dummy data)</Text>
        <View style={styles.row}>
          <View style={styles.columnLeft}>
            <Text style={{ fontWeight: "bold" }}>Shree Balaji Agency</Text>
            <Text>Navin Kumbhar Vasahat, Opp Church, Gadhinglaj</Text>
            <Text>FSSAI No: 11517043000914</Text>
            <Text>GSTIN/UIN: 27CEYPK3647A1ZW</Text>
            <Text>State Name: Maharashtra, Code: 27</Text>
            <Text>Email: shreebalajiagency6435@gmail.com</Text>
          </View>
          <View style={styles.columnRight}>
            <Text style={{ fontWeight: "bold" }}>Invoice No.: 2031</Text>
            <Text>Delivery Note:</Text>
            <Text>Dispatch Doc No:</Text>
            <Text>Dispatched through:</Text>
          </View>
          <View style={styles.columnRight}>
            <Text>Dated: 11-Sep-24</Text>
            <Text>{"\u00A0\u00A0\u00A0\u00A0"}</Text>{" "}
            {/* Four non-breaking spaces */}
            <Text>Delivery Note Date:</Text>
            <Text>Destination:</Text>
          </View>
        </View>

        {/* Buyer Section */}
        <View style={[styles.columnLeft, { marginBottom: 10 }]}>
          <Text style={{ fontWeight: "bold" }}>Buyer (Bill to):</Text>
          <Text>New Aapla Bazar Gadhinglaj</Text>
          <Text>GSTIN/UIN: 27CUQPP3237A1ZK</Text>
          <Text>State Name: Maharashtra, Code: 27</Text>
          <Text>Contact: 7385307133, 8805363842</Text>
        </View>

        {/* Table Header */}
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={[styles.tableCol, { width: "5%" }]}>
              <Text style={styles.tableCell}>S No.</Text>
            </View>
            <View style={[styles.tableCol, { width: "20%" }]}>
              <Text style={styles.tableCell}>Description of Goods</Text>
            </View>
            <View style={[styles.tableCol, { width: "10%" }]}>
              <Text style={styles.tableCell}>HSN/SAC</Text>
            </View>
            <View style={[styles.tableCol, { width: "5%" }]}>
              <Text style={styles.tableCell}>GST</Text>
            </View>
            <View style={[styles.tableCol, { width: "20%" }]}>
              <Text style={styles.tableCell}>Quantity</Text>
              <View style={{ flexDirection: "row", width: "100%" }}>
                <Text
                  style={[
                    styles.tableCell,
                    { width: "50%", borderRightWidth: 1 },
                  ]}
                >
                  Shipped
                </Text>
                <Text style={[styles.tableCell, { width: "50%" }]}>Billed</Text>
              </View>
            </View>
            <View style={[styles.tableCol, { width: "10%" }]}>
              <Text style={styles.tableCell}>Rate (Incl. of Tax)</Text>
            </View>

            <View style={[styles.tableCol, { width: "10%" }]}>
              <Text style={styles.tableCell}>Rate</Text>
            </View>
            {/* New Column */}
            <View style={[styles.tableCol, { width: "5%" }]}>
              <Text style={styles.tableCell}>Per</Text>
            </View>
            <View style={[styles.tableCol, { width: "5%" }]}>
              <Text style={styles.tableCell}>Disc %</Text>
            </View>
            <View style={[styles.tableCol, { width: "10%" }]}>
              <Text style={styles.tableCell}>Amount</Text>
            </View>
          </View>

          {/* Table Rows */}
          {[...Array(5).keys()].map((i) => (
            <View key={i} style={styles.tableRow}>
              <View style={[styles.tableCol, { width: "5%" }]}>
                <Text style={styles.tableCell}>{i + 1}</Text>
              </View>
              <View style={[styles.tableCol, { width: "20%" }]}>
                <Text style={styles.tableCell}>
                  Mango Pickle 5kg Jar Rs.380 {i + 1}
                </Text>
              </View>
              <View style={[styles.tableCol, { width: "10%" }]}>
                <Text style={styles.tableCell}>2001900</Text>
              </View>
              <View style={[styles.tableCol, { width: "5%" }]}>
                <Text style={styles.tableCell}>5%</Text>
              </View>
              <View
                style={[
                  styles.tableCol,
                  { width: "20%", flexDirection: "row" },
                ]}
              >
                <Text
                  style={[
                    styles.tableCell,
                    { width: "50%", borderRightWidth: 1 },
                  ]}
                >
                  4 nos
                </Text>
                <Text style={[styles.tableCell, { width: "50%" }]}>4 nos</Text>
              </View>
              <View style={[styles.tableCol, { width: "10%" }]}>
                <Text style={styles.tableCell}>250.00</Text>
              </View>

              <View style={[styles.tableCol, { width: "10%" }]}>
                <Text style={styles.tableCell}>223.21</Text>
              </View>
              {/* New Column Data */}
              <View style={[styles.tableCol, { width: "5%" }]}>
                <Text style={styles.tableCell}>KG</Text>
              </View>
              <View style={[styles.tableCol, { width: "5%" }]}>
                <Text style={styles.tableCell}>0%</Text>
              </View>
              <View style={[styles.tableCol, { width: "10%" }]}>
                <Text style={styles.tableCell}>892.84</Text>
              </View>
            </View>
          ))}
          <View style={styles.tableRow}>
            <View style={[styles.tableCol, { width: "5%" }]}>
              <Text style={styles.tableCell}></Text>
            </View>
            <View style={[styles.tableCol, { width: "20%" }]}>
              <Text style={styles.tableCell}>CGST</Text>
              <Text style={styles.tableCell}>SGST</Text>
              <Text style={styles.tableCell}>Less: Round Off </Text>
              <Text style={styles.tableCell}>Less: Discount </Text>
            </View>
            <View style={[styles.tableCol, { width: "10%" }]}>
              <Text style={styles.tableCell}>{"\u00A0\u00A0\u00A0\u00A0"}</Text>
            </View>
            <View style={[styles.tableCol, { width: "5%" }]}>
              <Text style={styles.tableCell}>{"\u00A0\u00A0\u00A0\u00A0"}</Text>
            </View>
            <View
              style={[styles.tableCol, { width: "20%", flexDirection: "row" }]}
            >
              <Text
                style={[
                  styles.tableCell,
                  { width: "50%", borderRightWidth: 1 },
                ]}
              >
                {"\u00A0\u00A0\u00A0\u00A0"}
              </Text>
              <Text style={[styles.tableCell, { width: "50%" }]}>
                {"\u00A0\u00A0\u00A0\u00A0"}
              </Text>
            </View>
            <View style={[styles.tableCol, { width: "10%" }]}>
              <Text style={styles.tableCell}>{"\u00A0\u00A0\u00A0\u00A0"}</Text>
            </View>
            <View style={[styles.tableCol, { width: "5%" }]}>
              <Text style={styles.tableCell}>{"\u00A0\u00A0\u00A0\u00A0"}</Text>
            </View>
            {/* New Column Data */}
            <View style={[styles.tableCol, { width: "10%" }]}>
              <Text style={styles.tableCell}>{"\u00A0\u00A0\u00A0\u00A0"}</Text>
            </View>
            <View style={[styles.tableCol, { width: "5%" }]}>
              <Text style={styles.tableCell}>{"\u00A0\u00A0\u00A0\u00A0"}</Text>
            </View>
            <View style={[styles.tableCol, { width: "10%" }]}>
              <Text style={styles.tableCell}>26,911.97</Text>
              <Text style={styles.tableCell}>{"\u00A0\u00A0\u00A0\u00A0"}</Text>
              <Text style={styles.tableCell}>705.05</Text>
              <Text style={styles.tableCell}>705.05</Text>
              <Text style={styles.tableCell}>(-) 0.07</Text>
              <Text style={styles.tableCell}>(-) 200.00</Text>
              <Text style={styles.tableCell}>{"\u00A0\u00A0\u00A0\u00A0"}</Text>
              <Text style={styles.tableCell}>28,120.00</Text>
            </View>
          </View>
        </View>

        {/* Footer Section */}
        <Text style={styles.footer}>
          Amount Chargeable (in words): INR Twenty Eight Thousand One Hundred
          Twenty Only.
        </Text>

        {/* HSN/SAC Breakdown */}
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={[styles.tableCol, { width: "40%" }]}>
              <Text style={styles.tableCell}>HSN/SAC</Text>
            </View>
            <View style={[styles.tableCol, { width: "10%" }]}>
              <Text style={styles.tableCell}>Taxable Value</Text>
            </View>
            <View style={[styles.tableCol, { width: "20%" }]}>
              <Text style={styles.tableCell}>Central Tax</Text>
              <View style={{ flexDirection: "row", width: "100%" }}>
                <Text
                  style={[
                    styles.tableCell,
                    { width: "50%", borderRightWidth: 1 },
                  ]}
                >
                  Rate
                </Text>
                <Text style={[styles.tableCell, { width: "50%" }]}>Amount</Text>
              </View>
            </View>
            <View style={[styles.tableCol, { width: "20%" }]}>
              <Text style={styles.tableCell}>State Tax</Text>
              <View style={{ flexDirection: "row", width: "100%" }}>
                <Text
                  style={[
                    styles.tableCell,
                    { width: "50%", borderRightWidth: 1 },
                  ]}
                >
                  Rate
                </Text>
                <Text style={[styles.tableCell, { width: "50%" }]}>Amount</Text>
              </View>
            </View>
            <View style={[styles.tableCol, { width: "10%" }]}>
              <Text style={styles.tableCell}>Total Tax Amount</Text>
            </View>
          </View>

          {/* Table Rows */}
          {[...Array(2).keys()].map((i) => (
            <View key={i} style={styles.tableRow}>
              <View style={[styles.tableCol, { width: "40%" }]}>
                <Text style={styles.tableCell}>2001900</Text>
              </View>
              <View style={[styles.tableCol, { width: "10%" }]}>
                <Text style={styles.tableCell}>892.84</Text>
              </View>
              <View
                style={[
                  styles.tableCol,
                  { width: "20%", flexDirection: "row" },
                ]}
              >
                <Text
                  style={[
                    styles.tableCell,
                    { width: "50%", borderRightWidth: 1 },
                  ]}
                >
                  6%
                </Text>
                <Text style={[styles.tableCell, { width: "50%" }]}>53.57</Text>
              </View>
              <View
                style={[
                  styles.tableCol,
                  { width: "20%", flexDirection: "row" },
                ]}
              >
                <Text
                  style={[
                    styles.tableCell,
                    { width: "50%", borderRightWidth: 1 },
                  ]}
                >
                  6%
                </Text>
                <Text style={[styles.tableCell, { width: "50%" }]}>53.57</Text>
              </View>
              {/* New Column Data */}
              <View style={[styles.tableCol, { width: "10%" }]}>
                <Text style={styles.tableCell}>107.14</Text>
              </View>
            </View>
          ))}
          <View style={styles.tableRow}>
            <View style={[styles.tableCol, { width: "40%" }]}>
              <Text style={styles.tableCell}>Total</Text>
            </View>
            <View style={[styles.tableCol, { width: "10%" }]}>
              <Text style={styles.tableCell}>26,911</Text>
            </View>
            <View
              style={[styles.tableCol, { width: "20%", flexDirection: "row" }]}
            >
              <Text
                style={[
                  styles.tableCell,
                  { width: "50%", borderRightWidth: 1 },
                ]}
              ></Text>
              <Text style={[styles.tableCell, { width: "50%" }]}>704.05</Text>
            </View>
            <View
              style={[styles.tableCol, { width: "20%", flexDirection: "row" }]}
            >
              <Text
                style={[
                  styles.tableCell,
                  { width: "50%", borderRightWidth: 1 },
                ]}
              ></Text>
              <Text style={[styles.tableCell, { width: "50%" }]}>810.06</Text>
            </View>
            {/* New Column Data */}
            <View style={[styles.tableCol, { width: "10%" }]}>
              <Text style={styles.tableCell}>1408.10</Text>
            </View>
          </View>
        </View>

        {/* Footer Section */}
        <Text style={styles.footer}>
          Amount Chargeable (in words): INR One Thousand Four Hundred Eight and
          Ten paise Only.
        </Text>

        {/* Bank Details Section */}
        <Text style={styles.footer}>Company's Bank Details:</Text>
        <Text style={styles.footer}>
          A/c Holder's Name: Shree Balaji Agency
        </Text>
        <Text style={styles.footer}>Bank Name: SHRI WARANA SAH BANK_114</Text>
        <Text style={styles.footer}>A/c No.: 397004086000114</Text>
        <Text style={styles.footer}>Branch & IFS Code: HDFC0CSWSBL</Text>

        {/* Declaration */}
        <Text style={styles.declaration}>
          We declare that this invoice shows the actual price of the goods
          described and that all particulars are true and correct.
        </Text>
        <Text style={styles.footerNote}>
          This is a Computer Generated Invoice
        </Text>
      </Page>
    </Document>
  );

  // Excel

  const exportToExcel = (data) => {
    const id = data[0]?._id || "N/A";
    const deliveredBy = data[0]?.stockistname?.[0] || "N/A";
    const BeatName = data[0]?.beat_name || "N/A";

    // Prepare the data in the format needed for the Excel file
    const tableHeaders = [
      ["SKU", "Rate", "Qty", "Total", "TPR", "Tax", "Free Pkts", "CD"],
    ];

    const itemid = data[0]?.itemid?.[0] || [];
    const price = data[0]?.price?.[0] || [];
    const quantity = data[0]?.quantity?.[0] || [];
    const tax = data[0]?.tax?.[0] || [];
    const free = data[0]?.free?.[0] || [];
    const cash_disc = data[0]?.cash_disc?.[0] || [];

    const tableRows = itemid.map((_, index) => [
      itemid[index] || "N/A",
      parseFloat(price[index] || 0).toFixed(2),
      parseFloat(quantity[index] || 0).toFixed(2),
      (
        parseFloat(price[index] || 0) * parseFloat(quantity[index] || 0)
      ).toFixed(2),
      0, // TPR - Placeholder, adjust as necessary
      parseFloat(tax[index] || 0).toFixed(2),
      parseFloat(free[index] || 0).toFixed(2),
      parseFloat(cash_disc[index] || 0).toFixed(2),
    ]);

    // Calculate totals
    const totalRate = price
      .reduce((acc, rate) => acc + parseFloat(rate || 0), 0)
      .toFixed(2);
    const totalQty = quantity
      .reduce((acc, qty) => acc + parseFloat(qty || 0), 0)
      .toFixed(2);
    const totalAmount = price
      .reduce(
        (acc, price, index) =>
          acc + parseFloat(price || 0) * parseFloat(quantity[index] || 0),
        0
      )
      .toFixed(2);
    const totalTax = tax
      .reduce((acc, tax) => acc + parseFloat(tax || 0), 0)
      .toFixed(2);
    const totalFree = free
      .reduce((acc, freeQty) => acc + parseFloat(freeQty || 0), 0)
      .toFixed(2);
    const totalCD = cash_disc
      .reduce((acc, disc) => acc + parseFloat(disc || 0), 0)
      .toFixed(2);

    // Add totals row
    const totalsRow = [
      "Total",
      totalRate, // Sum of rates
      totalQty,
      totalAmount,
      "0", // TPR total - adjust as needed
      totalTax,
      totalFree,
      totalCD,
    ];

    const totalsRow1 = ["Discount", "", "", Discounted_Amount, "", "", "", ""];
    const totalsRow2 = [
      "Discounted Amount",
      "",
      "",
      totalAmount - Discounted_Amount,
      "",
      "",
      "",
      "",
    ];

    // Add header information
    const tableHeadersID = [["PayerID", id]];
    const tableHeaders_deliveredBy = [["Delivered By", deliveredBy]];
    const tableHeaders_BeatName = [["Beat Name", BeatName]];

    // Combine headers and rows
    const excelData = [
      ...tableHeadersID,
      ...tableHeaders_deliveredBy,
      ...tableHeaders_BeatName,
      ...tableHeaders,
      ...tableRows,
      totalsRow, // Append the totals row
      totalsRow1, // Append the discount row
      totalsRow2, // Append the discounted amount row
    ];

    // Create a worksheet
    const worksheet = XLSX.utils.aoa_to_sheet(excelData);

    // Create a workbook
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Invoice");

    // Export the file
    XLSX.writeFile(workbook, `${id}_${deliveredBy}.xlsx`);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth={maxWidth}
      fullWidth={true}
      fullScreen={fullscreen}
    >
      <DialogTitle
        id="customized-dialog-title"
        // sx={{ bgcolor: "#5052ba", color: "white" }}
        sx={{ bgcolor: MyBgColor ? MyBgColor : "#5052ba", color: "white" }}
      >
        <Grid container spacing={2}>
          <Grid item xs={9}>
            {title}
          </Grid>
          <Grid item xs={1}>
            {PDFData ? (
              <PDFDownloadLink
                document={<InvoiceDocument />}
                fileName="invoice.pdf"
              >
                {({ loading }) => (
                  <Button sx={{ color: "white", border: "1px solid #c7b4b4" }}>
                    {loading ? "Loading document..." : PDFData}
                  </Button>
                )}
              </PDFDownloadLink>
            ) : null}
          </Grid>
          <Grid item xs={2}>
            {ExcelData ? (
              <Button
                sx={{ color: "white", border: "1px solid #c7b4b4" }}
                onClick={() => exportToExcel(ViewDATA)}
              >
                {ExcelData}
              </Button>
            ) : null}
          </Grid>
        </Grid>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "#ff0000fa",
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent sx={{ paddingTop: "2px" }}>{children}</DialogContent>
      <Divider />
    </Dialog>
  );
};

CustomDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  fullscreen: PropTypes.bool,
  maxWidth: PropTypes.oneOfType([
    PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]),
    PropTypes.bool,
  ]),
};

export default CustomDialog;
