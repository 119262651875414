import React, { useCallback, useEffect, useState } from "react";
import { Grid, Card, CardContent, Typography, Box } from "@mui/material";
import { getRequestInstanceOne } from "../../../apiServices/apiCall";

// Utility function to format numbers with commas and add units
const numberWithCommas = (x, unit) => {
  const formattedNumber = x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return `${formattedNumber} ${unit}`;
};

const TotalRevenue = ({
  selectedSalesgroup,
  selectedStockist,
  selectedNames,
  customDateRange,
}) => {
  const [totalRevenueKg, setTotalRevenueKg] = useState(0);
  const [totalRevenuePkt, setTotalRevenuePkt] = useState(0);

  const fetchData = useCallback(async () => {
    try {
      const params = new URLSearchParams();
      if (customDateRange) {
        params.append("start_date", customDateRange.start_date);
        params.append("end_date", customDateRange.end_date);
      }
      if (selectedSalesgroup) {
        params.append("salesgroup", selectedSalesgroup);
      }
      if (selectedStockist && localStorage.getItem("user") === "admin") {
        params.append("stockist", selectedStockist);
      }
      if (localStorage.getItem("user") !== "admin") {
        params.append("user", localStorage.getItem("user"));
      }
      if (selectedNames) {
        params.append("name", selectedNames);
      }
      const url = `/api/productRevenue?${params.toString()}`;
      const response = await getRequestInstanceOne(url);
      console.log("response ===>", response);
      if (response && response.data) {
        setTotalRevenueKg(response.data.TotalRevenueKg);
        setTotalRevenuePkt(response.data.TotalRevenuePkt);
      } else {
        console.error("Error: Invalid response structure or data");
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  }, [selectedSalesgroup, selectedStockist, selectedNames, customDateRange]);

  useEffect(() => {
    fetchData();

    // Set up polling to fetch data every minute
    // const intervalId = setInterval(() => {
    //     fetchData();
    // }, 60000); // 60 seconds

    // Clean up the interval on component unmount
    // return () => clearInterval(intervalId);
  }, [
    selectedSalesgroup,
    selectedStockist,
    selectedNames,
    customDateRange,
    fetchData,
  ]);

  const totalRevenue = totalRevenueKg + totalRevenuePkt;

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Card sx={{ height: "100%", backgroundColor: "#4042a3" }}>
          {/* #4042a3 */}
          <CardContent>
            <Box mb={2}>
              <Typography
                variant="h6"
                color="hsl(39, 100%, 50%)"
                gutterBottom
                fontFamily="Arial, sans-serif"
              >
                Orders in KG
              </Typography>
            </Box>
            {totalRevenueKg !== 0 ? (
              <Typography
                variant="h4"
                color="hsl(39, 100%, 50%)"
                fontFamily="Arial, sans-serif"
              >
                {numberWithCommas(totalRevenueKg, "kg")}
              </Typography>
            ) : (
              <Typography
                variant="body1"
                fontFamily="Arial, sans-serif"
                color="#d3d4ee"
              >
                No data available for the selected date range.
              </Typography>
            )}
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card sx={{ height: "100%", backgroundColor: "hsl(39, 100%, 50%)" }}>
          {/* hsl(39, 100%, 50%) */}
          <CardContent>
            <Box mb={2}>
              <Typography
                variant="h6"
                color="#4042a3"
                gutterBottom
                fontFamily="Arial, sans-serif"
              >
                Orders in PKT
              </Typography>
            </Box>
            {totalRevenuePkt !== 0 ? (
              <Typography
                variant="h4"
                color="#4042a3"
                fontFamily="Arial, sans-serif"
              >
                {numberWithCommas(totalRevenuePkt, "pkt")}
              </Typography>
            ) : (
              <Typography variant="body1" fontFamily="Arial, sans-serif">
                No data available for the selected date range.
              </Typography>
            )}
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card sx={{ height: "100%", backgroundColor: "#e0f7fa" }}>
          <CardContent>
            <Box mb={2}>
              <Typography
                variant="h6"
                gutterBottom
                fontFamily="Arial, sans-serif"
              >
                Orders of KG+PKT
              </Typography>
            </Box>
            {totalRevenue !== 0 ? (
              <Box>
                <Typography
                  variant="h4"
                  color="textSecondary"
                  fontFamily="Arial, sans-serif"
                >
                  {numberWithCommas(totalRevenueKg + totalRevenuePkt, "kg+pkt")}
                </Typography>
              </Box>
            ) : (
              <Typography variant="body1" fontFamily="Arial, sans-serif">
                No data available for the selected date range.
              </Typography>
            )}
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default TotalRevenue;
