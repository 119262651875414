import React, { useEffect, useRef, useState, useCallback } from "react";
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Stack,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TextField,
  Grid,
  TablePagination,
  Tooltip,
  Modal,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { DeleteForeverOutlined } from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";
import * as XLSX from "xlsx";
import { getRequestInstanceOne } from "../../apiServices/apiCall";
import { CustomLoader } from "../../components/CustomLoader/CustomLoader";
import SmallCard from "../Dashboard/SmallCard/SmallCard";

// Styled components for better font and table styling
const StyledTableCell = styled(TableCell)(({ theme, isHeader, isFooter }) => ({
  backgroundColor: isHeader ? "#d3d4ee" : isFooter ? "#f5f5f5" : "transparent",
  color: theme.palette.common.black,
  padding: "12px",
  textAlign: "center",
  position: isHeader || isFooter ? "sticky" : "relative",
  top: isHeader ? 0 : "auto",
  bottom: isFooter ? 0 : "auto",
  zIndex: isHeader || isFooter ? 1 : "auto",
  fontWeight: isHeader ? 700 : 400, // Bolder header font
  fontFamily: "Roboto, sans-serif", // Modern font family
  fontSize: isHeader ? "16px" : "14px", // Larger header font
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.background.default,
  },
  "&:hover": {
    backgroundColor: "#f0f0f5", // Add hover effect
  },
  fontFamily: "Roboto, sans-serif", // Modern font family
  fontSize: "14px",
  padding: "8px 12px",
  [theme.breakpoints.down("sm")]: {
    fontSize: "12px",
    padding: "6px 10px",
  },
}));

// Styling for the image viewer
const StyledImage = styled("img")({
  maxWidthwidth: "100%",
  borderRadius: "10px", // Rounded corners
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Add a subtle shadow
  border: "1px solid #ccc", // Light border
});
const TableWrapper = styled(Box)({
  width: "100%",
  height: "100%",
  overflowX: "auto",
});

const headerCellStyles = {
  fontFamily: "'Roboto', sans-serif", // Modern, clean font
  fontWeight: "bold", // Make the header bold
  fontSize: "12.5px", // Set a larger font size for readability
  color: "#5051ba", // Primary color for the header text
  letterSpacing: "0.05em", // Slight letter spacing for a clean look
  textTransform: "uppercase", // Uppercase text for emphasis
  borderBottom: "2px solid #3032a3", // Custom border for the header
  padding: "8px 8px", // Adjust padding for better alignment
  backgroundColor: "#dee1f5", // Optional: light background for header
};

const Gift = () => {
  const today = new Date().toISOString().split("T")[0];

  const [giftData, setGiftData] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);
  const [filter, setFilter] = useState("Today");
  const [startDate, setStartDate] = useState(today);
  const [endDate, setEndDate] = useState(today);
  const [customDateRange, setCustomDateRange] = useState({
    start_date: "",
    end_date: "",
  });
  const [exportDialogOpen, setExportDialogOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [searchTerm, setSearchTerm] = useState(""); // State for search term
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);
  const [currentPlaceholder, setCurrentPlaceholder] = useState("");
  // For editing
  const [open, setOpen] = useState(false); // Modal open/close state
  const [remark, setRemark] = useState(); // Track the current remark
  const [updatedRemark, setUpdatedRemark] = useState(); // Track the updated remark
  let Id = useRef("");

  // Debounce search term
  useEffect(() => {
    setIsLoading(true);
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm); // debouncedSearchTerm will change after delay
      setCurrentPage(0);
      setIsLoading(false);
    }, 2000); // Adjust the debounce time as needed (2000ms)

    return () => {
      clearTimeout(handler); // Clear the timeout on cleanup
    };
  }, [searchTerm]);

  // calling the api
  const fetchGiftApiData = useCallback(async () => {
    setIsLoading(true);
    const params = new URLSearchParams({
      page: currentPage + 1,
      per_page: rowsPerPage,
      start_date: startDate,
      end_date: endDate,
    });

    if (debouncedSearchTerm) {
      params.append("query_string", debouncedSearchTerm);
    }

    const url = `/api/gift_api?${params.toString()}`;

    try {
      const result = await getRequestInstanceOne(url);
      const fetchedGiftData = result.data[0].data;
      const totalRecords = result.data[0].totalCount[0].count;
      setGiftData(fetchedGiftData);
      setTotalCount(totalRecords);
    } catch (error) {
      console.error("Error fetching the API:", error);
    } finally {
      setIsLoading(false);
    }
  }, [currentPage, rowsPerPage, startDate, endDate, debouncedSearchTerm]);

  useEffect(() => {
    if (startDate && endDate) {
      fetchGiftApiData();
    }
  }, [fetchGiftApiData]);

  const handleFilterSelect = (filter) => {
    setGiftData([]);
    setFilter(filter);
    const today = new Date();
    let start_date, end_date;

    switch (filter) {
      case "Today":
        start_date = end_date = today.toISOString().split("T")[0];
        break;
      case "Weekly":
        start_date = new Date(today.setDate(today.getDate() - 7))
          .toISOString()
          .split("T")[0];
        end_date = new Date().toISOString().split("T")[0];
        break;
      case "Monthly":
        start_date = new Date(today.setMonth(today.getMonth() - 1))
          .toISOString()
          .split("T")[0];
        end_date = new Date().toISOString().split("T")[0];
        break;
      case "Yearly":
        start_date = new Date(today.setFullYear(today.getFullYear() - 1))
          .toISOString()
          .split("T")[0];
        end_date = new Date().toISOString().split("T")[0];
        break;
      case "DateRange":
        return;
      default:
        return;
    }

    setCustomDateRange({ start_date, end_date });
    setStartDate(start_date);
    setEndDate(end_date);
    setCurrentPage(0);
  };

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };

  const handleViewImages = (images) => {
    setSelectedImages(images);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedImages([]);
  };

  const handleOpenExportDialog = () => {
    setExportDialogOpen(true);
  };

  const handleCloseExportDialog = () => {
    setExportDialogOpen(false);
  };

  const handleExportExcel = async () => {
    setIsLoading(true);
    const params = new URLSearchParams({
      start_date: startDate,
      end_date: endDate,
    });

    const url = `/api/gift_api?${params.toString()}`;

    try {
      // Use getRequestInstanceOne to fetch data
      const result = await getRequestInstanceOne(url);

      // Assuming result contains the JSON data directly
      const fetchedGiftData = result.data[0].data;

      // Format the fetched data for Excel export
      const formattedData = fetchedGiftData.map((row) => ({
        Salesgroup: row.r_info.salesgroup,
        "Payer Id": row.r_info.payerId,
        Name: row.gift_by.name,
        Date: row.date,
        "Offer Id": row.offerId,
        "Offer Name": row.offer_name,
        "Outlet Id": row.outletId,
        Remark: row.remark,
        Gift_count: row.gift_count,
        Image: row.img.join(", "), // Join multiple images into a single cell
      }));

      const ws = XLSX.utils.json_to_sheet(formattedData);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Gift Data");
      XLSX.writeFile(
        wb,
        `GiftData_${customDateRange.start_date}_${customDateRange.end_date}.xlsx`
      );
    } catch (error) {
      console.error("Error fetching the API:", error);
    } finally {
      setIsLoading(false);
      setExportDialogOpen(false);
    }
  };

  // Filter giftData based on the search term
  const trimmedSearchTerm = searchTerm.trim().toLowerCase();

  const filteredData = giftData.filter(
    (row) =>
      (row.gift_by?.name?.toLowerCase() || "").includes(trimmedSearchTerm) ||
      (row.r_info.salesgroup?.toLowerCase() || "").includes(
        trimmedSearchTerm
      ) ||
      (row.r_info.payerId?.toLowerCase() || "").includes(trimmedSearchTerm) ||
      (row.date?.toLowerCase() || "").includes(trimmedSearchTerm) ||
      (row.offer_name?.toLowerCase() || "").includes(trimmedSearchTerm) ||
      (row.offerId?.toLowerCase() || "").includes(trimmedSearchTerm) ||
      (row.outletId?.toLowerCase() || "").includes(trimmedSearchTerm) ||
      (row.remark?.toLowerCase() || "").includes(trimmedSearchTerm)
  );

  // mutli placeholder show
  const placeholders = [
    "by Salesgroup...",
    "by Payer Id...",
    "by Name...",
    "by Date...",
    "by Offer Id...",
    "by Offer name...",
    "by Outlet Id...",
    "by Remark...",
  ];

  useEffect(() => {
    let index = 0;

    const interval = setInterval(() => {
      setCurrentPlaceholder(placeholders[index]);
      index = (index + 1) % placeholders.length; // Cycle through the array
    }, 2000); // Change placeholder every 2 seconds

    return () => clearInterval(interval); // Clean up the interval on component unmount
  }, []);

  // Making text ellipsis... start
  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };

  // For deleting

  const DeleteData = async (_id) => {
    setIsLoading(true);
    Id.current = _id; // Set the current value of _id in the ref
    const data = false;
    // Ask for user confirmation
    const confirmed = window.confirm(
      "Are you sure you want to delete this item?"
    );

    if (confirmed) {
      // If the user confirms, proceed with the delete request
      const response = await fetch(
        `http://143.244.136.36:8084/api/delete_giftdashboard_api?_id=${Id.current}&data=${data}`,
        // `http://192.168.0.26:8080/api/delete_giftdashboard_api?_id=${Id.current}&data=${data}`,
        {
          method: "POST",
        }
      );
      // Refresh the page after the response is received
      if (response.ok) {
        // window.location.reload();
        fetchGiftApiData(); // Refresh the data after updating the remark
        toast.success("Successfully Deleted!");
        setIsLoading(false);
      } else {
        toast.error("Failed to delete the data");
        setIsLoading(false);
      }
    } else {
      toast.error("Delete action was canceled.");
      setIsLoading(false);
    }
  };

  // for editing
  const handleOpen = (_id, currentRemark) => {
    Id.current = _id; // Set the ID for the selected row
    setUpdatedRemark(currentRemark); // Set the initial remark value in the modal
    setOpen(true); // Open the modal
  };
  const handleClose = () => {
    setOpen(false);
    setUpdatedRemark(""); // Clear the remark when closing
  };

  const handleEdit = async () => {
    setIsLoading(true);

    try {
      // Call the API to update the remark
      const response = await axios.post(
        `http://143.244.136.36:8084/api/edit_remarks_giftdashboard?_id=${Id.current}&remark=${updatedRemark}`
        // `http://192.168.0.26:8080/api/edit_remarks_giftdashboard?_id=${Id.current}&remark=${updatedRemark}`
      );
      if (response.status === 200) {
        setRemark(updatedRemark); // Update the remark locally after successful API call
        toast.success("Remark successfully updated!"); // Show success message
        handleClose(); // Close the modal
        fetchGiftApiData(); // Refresh the data after updating the remark
        setIsLoading(false);
      }
    } catch (error) {
      toast.error("Failed to update the remark:", error);
      setIsLoading(false);
    }
  };

  return (
    <>
      <Box>
        <Toaster />
        <Grid container spacing={2} alignItems="stretch">
          {/* Left: Gift Table Section */}
          <Grid item xs={12} md={3}>
            {filter !== "DateRange" && (
              <Typography
                variant="h5"
                gutterBottom
                sx={{
                  fontSize: "24px",
                  fontWeight: "bold",
                  color: "#7d7d85",
                  textAlign: "center",
                  letterSpacing: "1.2px",
                  fontFamily: "'Roboto', sans-serif",
                  textTransform: "uppercase",
                  marginBottom: "20px",
                  textDecorationColor: "#8a8ae1",
                  textDecorationThickness: "2px",
                }}
              >
                Gift Table
              </Typography>
            )}
            {/* Your Gift Table component or logic goes here */}
          </Grid>

          {/* Right: Controls Section */}
          <Grid item xs={12} md={filter === "DateRange" ? 12 : 9}>
            <Grid
              container
              spacing={2}
              justifyContent="flex-end"
              alignItems="stretch"
            >
              {/* Date Range Picker */}
              {filter === "DateRange" && (
                <Grid item>
                  <Box display="flex" gap={2}>
                    <TextField
                      label="Start Date"
                      type="date"
                      value={customDateRange.start_date || ""}
                      onChange={(e) =>
                        setCustomDateRange({
                          ...customDateRange,
                          start_date: e.target.value,
                        })
                      }
                      InputLabelProps={{ shrink: true }}
                      sx={{
                        height: 42, // Set the height
                        "& .MuiInputBase-root": {
                          height: 42, // Set input height
                          padding: "0 12px", // Adjust padding
                        },
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: "#5051ba", // Customize border color
                          },
                          "&:hover fieldset": {
                            borderColor: "#4042a3", // Hover border color
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "#3032a3", // Focused border color
                          },
                        },
                      }}
                    />
                    <TextField
                      label="End Date"
                      type="date"
                      value={customDateRange.end_date || ""}
                      onChange={(e) => {
                        setCustomDateRange({
                          ...customDateRange,
                          end_date: e.target.value,
                        });
                      }}
                      InputLabelProps={{ shrink: true }}
                      sx={{
                        height: 42, // Set the height
                        "& .MuiInputBase-root": {
                          height: 42, // Set input height
                          padding: "0 12px", // Adjust padding
                        },
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: "#5051ba", // Customize border color
                          },
                          "&:hover fieldset": {
                            borderColor: "#4042a3", // Hover border color
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "#3032a3", // Focused border color
                          },
                        },
                      }}
                    />
                    <Button
                      variant="contained"
                      sx={{
                        background: "#5052ba",
                        height: 42, // Set button height
                        minWidth: "auto", // Avoid overly large button width
                        padding: "0 16px", // Adjust padding for height
                        "&:hover": {
                          backgroundColor: "#4042a3", // Change hover color
                        },
                      }}
                      onClick={() => {
                        setStartDate(customDateRange.start_date);
                        setEndDate(customDateRange.end_date);
                        setCurrentPage(0);
                      }}
                      disabled={!customDateRange.end_date}
                    >
                      Apply Date
                    </Button>
                  </Box>
                </Grid>
              )}

              {/* Date Filter Dropdown */}
              <Grid item>
                <FormControl
                  sx={{ minWidth: 216, height: 40, marginBottom: 2 }}
                >
                  <InputLabel
                    sx={{
                      color: "#5051ba",
                      "&.Mui-focused": {
                        color: "#3032a3",
                      },
                    }}
                  >
                    Date Filter
                  </InputLabel>
                  <Select
                    value={filter}
                    label="Date Filter"
                    onChange={(e) => handleFilterSelect(e.target.value)}
                    sx={{
                      height: 40,
                      "& .MuiSelect-select": {
                        paddingTop: "8px",
                        paddingBottom: "8px",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#5051ba",
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#4042a3",
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#3032a3",
                      },
                    }}
                  >
                    <MenuItem value="Today">Today</MenuItem>
                    <MenuItem value="Weekly">Weekly</MenuItem>
                    <MenuItem value="Monthly">Monthly</MenuItem>
                    <MenuItem value="Yearly">Yearly</MenuItem>
                    <MenuItem value="DateRange">Date Range</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              {/* Search Field */}
              <Grid item>
                <TextField
                  variant="outlined"
                  size="small"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  placeholder={`Search ${currentPlaceholder}`} // Dynamic placeholder
                  sx={{
                    width: "216px",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "#5051ba",
                      },
                      "&:hover fieldset": {
                        borderColor: "#4042a3",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#3032a3",
                      },
                    },
                    "& .MuiInputLabel-root": {
                      color: "#5051ba",
                    },
                    "& .MuiInputLabel-root.Mui-focused": {
                      color: "#3032a3",
                    },
                    "& .MuiInputBase-input": {
                      padding: "8px",
                      "&::placeholder": {
                        color: "green", // Customize placeholder color here
                        opacity: 1, // Ensure placeholder is fully opaque if needed
                      },
                    },
                  }}
                />
              </Grid>

              {/* Export to Excel Button */}
              <Grid item>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor:
                      giftData.length === 0 ? "#b0b0b0" : "#5051ba",
                    color: "#fff",
                    "&:hover": {
                      backgroundColor:
                        giftData.length === 0 ? "#b0b0b0" : "black",
                    },
                  }}
                  onClick={handleOpenExportDialog}
                  disabled={giftData.length === 0}
                >
                  Export Excel
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {/* Table to display the data */}
        <CustomLoader open={isLoading} />
        <TableWrapper>
          <TableContainer component={Paper}>
            <Table stickyHeader>
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell isHeader sx={headerCellStyles}>
                    Salesgroup
                  </StyledTableCell>
                  <StyledTableCell isHeader sx={headerCellStyles}>
                    Payer Id
                  </StyledTableCell>
                  <StyledTableCell isHeader sx={headerCellStyles}>
                    Name
                  </StyledTableCell>
                  <StyledTableCell isHeader sx={headerCellStyles}>
                    Date
                  </StyledTableCell>
                  <StyledTableCell isHeader sx={headerCellStyles}>
                    Offer Id
                  </StyledTableCell>
                  <StyledTableCell isHeader sx={headerCellStyles}>
                    Offer Name
                  </StyledTableCell>
                  <StyledTableCell isHeader sx={headerCellStyles}>
                    Outlet Id
                  </StyledTableCell>
                  <StyledTableCell isHeader sx={headerCellStyles}>
                    Remark
                  </StyledTableCell>
                  <StyledTableCell isHeader sx={headerCellStyles}>
                    Gift Count
                  </StyledTableCell>
                  <StyledTableCell isHeader sx={headerCellStyles}>
                    Images
                  </StyledTableCell>
                  <StyledTableCell isHeader sx={headerCellStyles}>
                    Delete
                  </StyledTableCell>
                </StyledTableRow>
              </TableHead>

              <TableBody>
                {filteredData.length > 0 ? (
                  filteredData.map((row) => (
                    <StyledTableRow key={row._id}>
                      <StyledTableCell>{row.r_info.salesgroup}</StyledTableCell>
                      <StyledTableCell>{row.r_info.payerId}</StyledTableCell>
                      <Tooltip
                        title={row.gift_by.name || "-"}
                        placement="top"
                        arrow
                      >
                        <StyledTableCell>
                          {truncateText(row.gift_by.name || "-", 11)}
                        </StyledTableCell>
                      </Tooltip>
                      <StyledTableCell>{row.date}</StyledTableCell>
                      <StyledTableCell>{row.offerId}</StyledTableCell>
                      <Tooltip
                        title={row.offer_name || "-"}
                        placement="top"
                        arrow
                      >
                        <StyledTableCell>
                          {truncateText(row.offer_name || "-", 11)}
                        </StyledTableCell>
                      </Tooltip>
                      <StyledTableCell>{row.outletId}</StyledTableCell>
                      <Tooltip title={row.remark || "-"} placement="top" arrow>
                        <StyledTableCell>
                          <span>
                            <IconButton
                              onClick={() => handleOpen(row._id, row.remark)}
                            >
                              <EditIcon
                                sx={{ color: "green", fontSize: "19px" }}
                              />
                            </IconButton>
                          </span>
                          {truncateText(row.remark || "-", 10)}
                        </StyledTableCell>
                      </Tooltip>

                      <StyledTableCell>{row.gift_count}</StyledTableCell>

                      <StyledTableCell>
                        <Button
                          variant="outlined"
                          sx={{
                            color: "#2e7d32",
                            borderColor: "#2e7d32",
                            "&:hover": {
                              borderColor: "#20b2aa",
                              color: "#20b2aa",
                            },
                          }}
                          onClick={() => handleViewImages(row.img)}
                        >
                          View Images
                        </Button>
                      </StyledTableCell>

                      <StyledTableCell>
                        <IconButton>
                          <DeleteForeverOutlined
                            sx={{ color: "red" }}
                            onClick={() => DeleteData(row._id)}
                          />
                        </IconButton>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))
                ) : (
                  <StyledTableRow>
                    <StyledTableCell
                      colSpan={9}
                      align="center"
                      sx={{ color: "#888" }}
                    >
                      No data is available
                    </StyledTableCell>
                  </StyledTableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </TableWrapper>

        {/* Pagination Controls */}
        <TablePagination
          component="div"
          count={totalCount}
          page={currentPage}
          onPageChange={handlePageChange}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleRowsPerPageChange}
        />

        {/* Image Viewer Dialog */}
        <Dialog open={openDialog} onClose={handleCloseDialog}>
          <DialogTitle
            sx={{ background: "#5051ba", color: "white", lineHeight: "0.6" }}
          >
            View Images
            <IconButton
              aria-label="close"
              onClick={handleCloseDialog}
              sx={{ position: "absolute", right: 8, top: 8 }}
            >
              <CloseIcon sx={{ color: "#ff0000fb" }} />
            </IconButton>
          </DialogTitle>
          <DialogContent sx={{ padding: "0" }}>
            <Stack direction="column" spacing={2}>
              {selectedImages.map((image, index) => (
                <StyledImage key={index} src={image} alt={`Image ${index}`} />
              ))}
            </Stack>
          </DialogContent>
        </Dialog>

        {/* Export Dialog */}
        <Dialog
          open={exportDialogOpen}
          onClose={handleCloseExportDialog}
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle
            sx={{
              color: "#556b2f",
              fontWeight: "bold",
              fontSize: "1.5rem",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            Export to Excel
            <IconButton
              aria-label="close"
              onClick={handleCloseExportDialog}
              sx={{
                color: "#556b2f",
                "&:hover": {
                  color: "red", // Change to your desired hover color
                },
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>

          <DialogContent sx={{ padding: "20px" }}>
            <Typography variant="body1" gutterBottom>
              Click "Export Now" to download the data as an Excel file.
            </Typography>

            <Button
              variant="contained"
              sx={{
                backgroundColor: "#5051ba",
                color: "#fff",
                mt: 2,
                padding: "7px 12px",
                fontSize: "12px",
                borderRadius: "5px",
                "&:hover": {
                  backgroundColor: "black", // Change this to your desired hover color
                },
              }}
              onClick={handleExportExcel}
            >
              Export Now
            </Button>
          </DialogContent>
        </Dialog>

        {/* for editing */}

        {/* Modal for editing the remark */}
        <Modal open={open} onClose={handleClose}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              borderRadius: 2,
              boxShadow: 24,
              p: 4,
            }}
          >
            <h3>Edit Remark</h3>
            <TextField
              fullWidth
              value={updatedRemark}
              onChange={(e) => setUpdatedRemark(e.target.value)}
              label="Remark"
            />
            <Box mt={2} display="flex" justifyContent="space-between">
              <Button variant="outlined" color="error" onClick={handleClose}>
                Cancel
              </Button>
              <Button variant="contained" color="primary" onClick={handleEdit}>
                Confirm
              </Button>
            </Box>
          </Box>
        </Modal>
      </Box>
    </>
  );
};

export default Gift;
