import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import { Grid, Typography } from "@mui/material";
import { getRequestInstanceOne } from "../../../apiServices/apiCall";
import { CustomLoader } from "../../../components/CustomLoader/CustomLoader";

const TopCustomersChart = ({
  selectedSalesgroup,
  customDateRange,
  selectedNames,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const [chartData, setChartData] = useState({
    options: {
      chart: {
        type: "bar",
        height: 350,
      },
      xaxis: {
        categories: [],
        labels: {
          formatter: function (val) {
            return val;
          },
        },
      },
      tooltip: {
        enabled: true,
      },
      yaxis: {
        title: {
          text: "Quantity",
        },
      },
      plotOptions: {
        bar: {
          columnWidth: "60%",
          endingShape: "rounded",
          dataLabels: {
            position: "top",
          },
        },
      },
      dataLabels: {
        enabled: true,
        offsetY: -20,
        style: {
          colors: ["#000"],
        },
      },
      colors: ["#1E3A8A", "#D97706"],
    },
    series: [
      {
        name: "Kg",
        data: [],
      },
      {
        name: "Pkt",
        data: [],
      },
    ],
  });

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const params = new URLSearchParams();
      params.append("start_date", customDateRange.start_date);
      params.append("end_date", customDateRange.end_date);
      if (localStorage.getItem("user") !== "admin") {
        params.append("user", localStorage.getItem("user"));
      }
      if (selectedSalesgroup) {
        params.append("salesGroup", selectedSalesgroup);
      }
      if (selectedNames) {
        params.append("name", selectedNames);
      }

      const response = await getRequestInstanceOne(
        `/api/retailer_kg_pkt_details?${params.toString()}`
      );
      if (response && response.data) {
        const data = response.data;

        // Extract data for the chart
        const categories = data.map((item) =>
          item.salesgroup && item.salesgroup.length > 0
            ? `${item._id} (${item.salesgroup.join(", ")})`
            : item._id
        );
        const kgData = data.map((item) => item.Kg);
        const pktData = data.map((item) => item.Pkt);

        setChartData((prevData) => ({
          ...prevData,
          options: {
            ...prevData.options,
            xaxis: {
              ...prevData.options.xaxis,
              categories: categories,
            },
          },
          series: [
            { name: "Kg", data: kgData },
            { name: "Pkt", data: pktData },
          ],
        }));
      } else {
        console.error("Invalid API response");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [selectedSalesgroup, customDateRange]);

  return (
    <div>
      <Grid>
        <Typography variant="h6">
          Top 10 Customers by Quantity Purchased (Kg and Pkt)
        </Typography>
      </Grid>
      {isLoading ? (
        <CustomLoader />
      ) : chartData.series && chartData.series[0].data.length > 0 ? (
        <>
          <Chart
            options={chartData.options}
            series={chartData.series}
            type="bar"
            height={350}
          />
        </>
      ) : (
        <Typography>No data available.</Typography>
      )}
    </div>
  );
};

export default TopCustomersChart;
