import React, { useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { styled } from "@mui/system";
import { useNavigate } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import toast, { Toaster, ToastBar } from "react-hot-toast";

const LoginCard = styled(Card)({
  maxWidth: 400,
  width: "100%",
  height: "auto",
  padding: "1rem",
  borderRadius: "12px",
  boxShadow: "1px 2px 4px 0px #726e6e",
  backgroundColor: "transparent",
});

const Login = ({ setToken }) => {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const handleLogin = async () => {
    try {
      const expirationTime = () => new Date().getTime() + 24 * 60 * 60 * 1000;

      const response = await fetch(`http://142.93.208.119/payer_login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token 8e50eafc4bb9fdaec2de3eecec1b184771a0d14f`, // Add your token here
        },
        body: JSON.stringify({
          payerId: username,
          password: password,
        }),
      });

      if (response.ok) {
        // const data = await response.json(); // Assuming the response contains JSON data
        const token = username.trim();
        // const user = username.trim();
        const user =
          username !== "admin"
            ? username.trim().toUpperCase()
            : username.trim();

        localStorage.setItem("token", token);
        localStorage.setItem("tokenExpiration", expirationTime());
        localStorage.setItem("user", user);
        setToken(token);
        navigate("/dashboard");
        // console.log(localStorage.getItem("user")?.trim() !== "admin");
      } else if (response.status === 401) {
        toast.error("Unauthorized: Invalid credentials.");
      } else {
        toast.error("Login failed. Please Check Username and Password.");
      }
    } catch (error) {
      toast.error("Login failed. Please try again.");
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleLogin();
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const textFieldStyles = {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#00000030",
      },
      "&:hover fieldset": {
        borderColor: "#00000030",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#00000070",
      },
    },
    "& .MuiInputLabel-root.Mui-focused": {
      color: "black",
    },
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        background: `radial-gradient(circle, rgb(250 215 140) 0%, rgb(135 185 185) 100%)`,
      }}
    >
      <LoginCard>
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            src="/sapat-logo.png"
            alt="Sapat Logo"
            style={{ height: 80, marginBottom: "1.5rem" }}
          />
          <TextField
            autoComplete="off"
            size="small"
            label="Username"
            variant="outlined"
            margin="normal"
            fullWidth
            value={username}
            sx={textFieldStyles}
            onChange={(e) => setUsername(e.target.value)}
            onKeyDown={handleKeyDown}
          />
          <TextField
            autoComplete="off"
            size="small"
            label="Password"
            type={showPassword ? "text" : "password"}
            variant="outlined"
            margin="normal"
            fullWidth
            value={password}
            sx={textFieldStyles}
            onChange={(e) => setPassword(e.target.value)}
            onKeyDown={handleKeyDown}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={togglePasswordVisibility} edge="end">
                    {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Button
            variant="contained"
            sx={{
              mt: 2,
              backgroundColor: "rgb(227, 30, 36)",
              color: "#fff",
              "&:hover": {
                backgroundColor: "#c62129",
              },
            }}
            onClick={handleLogin}
            fullWidth
          >
            Login
            <Toaster>
              {(t) => (
                <ToastBar
                  toast={t}
                  style={{
                    ...t.style,
                    animation: t.visible
                      ? "custom-enter 0.5s ease" // Enter animation duration set to 0.5s
                      : "custom-exit 0.5s ease", // Exit animation duration set to 0.5s
                  }}
                />
              )}
            </Toaster>
          </Button>
        </CardContent>
      </LoginCard>
    </Box>
  );
};

export default Login;
