import { axiosInstance1, axiosInstance2 } from './axiosInstance';

export const getRequestInstanceOne = async (endpoint, params) => {
    try {
        const response = await axiosInstance1.get(endpoint, { params });
        return response.data; 
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
};

export const getRequestInstanceTwo = async (endpoint, params) => {
    try {
        const response = await axiosInstance2.get(endpoint, { params });
        console.log('response ==>',response)
        return response.data; 
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
};

export const getRequestForDownload = async (endpoint) => {
    try {
        const response = await axiosInstance1.get(endpoint,{responseType : 'blob'});
        return response.data;
    } catch (error) {
        // Handle error as needed
        console.error('Error fetching data:', error);
        throw error;
    }
};

export const postRequest = async (endpoint, body) => {
    try {
        console.log('endpoint', endpoint);
        console.log('body', body);
        const response = await axiosInstance1.post(endpoint, body);
        return response.data;
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
};