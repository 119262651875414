import React, { useState, useEffect } from "react";
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from "@mui/material";
import CategoryIcon from "@mui/icons-material/Category";
import DashboardIcon from "@mui/icons-material/Dashboard";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import AcUnitIcon from "@mui/icons-material/AcUnit";
import TableRowsIcon from "@mui/icons-material/TableRows";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import { useLocation } from "react-router-dom";

const DrawerList = ({ open, navigate }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const location = useLocation();
  const [userRole, setUserRole] = useState("");

  useEffect(() => {
    const token = localStorage.getItem("token");
    setUserRole(token);
  }, []);

  useEffect(() => {
    const currentPath = location.pathname.replace("/", "");
    const menuToUse = userRole === "admin" ? menuItems : filteredMenuItems;
    const currentIndex = menuToUse.findIndex(
      (item) => item.text.toLowerCase().replace(" ", "-") === currentPath
    );
    if (currentIndex !== -1) {
      setActiveIndex(currentIndex);
    }
  }, [location.pathname, userRole]);

  const handleListItemClick = (index, path) => {
    setActiveIndex(index);
    navigate(path);
  };

  const menuItems = [
    { text: "Dashboard", icon: <DashboardIcon /> },
    { text: "Categories", icon: <CategoryIcon /> },
    { text: "All Orders", icon: <AcUnitIcon /> },
    { text: "Sales Offer", icon: <LocalOfferIcon /> },
    { text: "Attendance", icon: <TableRowsIcon /> },
    { text: "Gift", icon: <CardGiftcardIcon /> },
  ];

  // Filter menu items based on user role
  const filteredMenuItems =
    userRole === "admin"
      ? menuItems
      : menuItems.filter(
          (item, index) => index === 0 || index === 2 || index === 3
        );

  return (
    <List>
      {filteredMenuItems.map((item, index) => (
        <ListItem key={item.text} disablePadding>
          {open ? (
            <ListItemButton
              selected={index === activeIndex}
              onClick={() =>
                handleListItemClick(
                  index,
                  `/${item.text.toLowerCase().replace(" ", "-")}`
                )
              }
              sx={{
                color:
                  index === activeIndex ? "#fff" : "rgba(255, 255, 255, 0.7)",
                "&.Mui-selected": {
                  bgcolor: "rgba(255, 255, 255, 0.16)",
                  "&:hover": {
                    bgcolor: "rgba(255, 255, 255, 0.24)",
                  },
                },
                "&:hover": {
                  bgcolor: "rgba(255, 255, 255, 0.08)",
                },
              }}
            >
              <ListItemIcon sx={{ color: "inherit" }}>{item.icon}</ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItemButton>
          ) : (
            <Tooltip
              title={item.text}
              placement="right"
              arrow
              PopperProps={{
                sx: {
                  "& .MuiTooltip-tooltip": {
                    bgcolor: "rgba(0, 0, 0, 0.87)",
                    color: "#fff",
                    fontSize: "1rem",
                    padding: "10px",
                  },
                  "& .MuiTooltip-arrow": {
                    color: "rgba(0, 0, 0, 0.87)",
                  },
                },
              }}
            >
              <ListItemButton
                selected={index === activeIndex}
                onClick={() =>
                  handleListItemClick(
                    index,
                    `/${item.text.toLowerCase().replace(" ", "-")}`
                  )
                }
                sx={{
                  color:
                    index === activeIndex ? "#fff" : "rgba(255, 255, 255, 0.7)",
                  "&.Mui-selected": {
                    bgcolor: "rgba(255, 255, 255, 0.16)",
                    "&:hover": {
                      bgcolor: "rgba(255, 255, 255, 0.24)",
                    },
                  },
                  "&:hover": {
                    bgcolor: "rgba(255, 255, 255, 0.08)",
                  },
                }}
              >
                <ListItemIcon sx={{ color: "inherit" }}>
                  {item.icon}
                </ListItemIcon>
              </ListItemButton>
            </Tooltip>
          )}
        </ListItem>
      ))}
    </List>
  );
};

export default DrawerList;
