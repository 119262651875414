import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import { Grid, Typography } from "@mui/material";
import { getRequestInstanceOne } from "../../../apiServices/apiCall";
import { CustomLoader } from "../../../components/CustomLoader/CustomLoader";

const WeeklyRevenueChart = ({
  selectedSalesgroup,
  selectedStockist,
  selectedNames,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  // Function to rotate days based on yesterday
  const getSortedDays = () => {
    const daysOfWeek = [
      "monday",
      "tuesday",
      "wednesday",
      "thursday",
      "friday",
      "saturday",
      "sunday",
    ];

    // Get today's date and calculate yesterday
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    // Get the day index (0 = Sunday, 1 = Monday, etc.) and rotate the days array
    const yesterdayIndex = yesterday.getDay(); // 0 for Sunday, 6 for Saturday
    const rotatedDays = daysOfWeek
      .slice(yesterdayIndex)
      .concat(daysOfWeek.slice(0, yesterdayIndex));

    return rotatedDays;
  };

  const [chartData, setChartData] = useState({
    options: {
      chart: {
        type: "bar",
        height: 350,
      },
      series: [
        {
          name: "Total Revenue (Kg+Pkt)",
          data: [],
        },
      ],
      xaxis: {
        categories: [],
        labels: {
          formatter: function (val) {
            return val;
          },
        },
      },
      tooltip: {
        enabled: true,
      },
      yaxis: {
        title: {
          text: "Revenue",
        },
      },
      plotOptions: {
        bar: {
          columnWidth: "60%",
          endingShape: "rounded",
          dataLabels: {
            position: "top",
          },
        },
      },
      dataLabels: {
        enabled: true,
        offsetY: -20,
        style: {
          colors: ["#000"], // Customize the color of the total labels
        },
      },
      colors: ["#D97706D9"],
    },
    series: [],
  });

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const params = new URLSearchParams();
      if (selectedSalesgroup) {
        params.append("salesgroup", selectedSalesgroup);
      }
      if (selectedStockist && localStorage.getItem("user") === "admin") {
        params.append("stockist", selectedStockist);
      }
      if (localStorage.getItem("user") !== "admin") {
        params.append("user", localStorage.getItem("user"));
      }
      if (selectedNames) {
        params.append("name", selectedNames);
      }

      const response = await getRequestInstanceOne(
        `/api/weekly-wise?${params.toString()}`
      );
      if (response && response.data) {
        const data = response.data;

        // Get the dynamically sorted days
        const sortedDays = getSortedDays();

        // Create a mapping of API data for easier lookup
        const apiDataMap = data.reduce((acc, item) => {
          acc[item.day.toLowerCase()] = item.total_revenue;
          return acc;
        }, {});

        // Initialize default data for each day, using API data when available
        const defaultData = sortedDays.map((day) => ({
          day,
          total_revenue: apiDataMap[day] || 0,
        }));

        const categories = defaultData.map(
          (item) => item.day.charAt(0).toUpperCase() + item.day.slice(1)
        );
        const seriesData = defaultData.map((item) => item.total_revenue);

        setChartData({
          options: {
            ...chartData.options,
            xaxis: {
              ...chartData.options.xaxis,
              categories,
            },
          },
          series: [
            {
              name: "Total Revenue (Kg+Pkt)",
              data: seriesData,
            },
          ],
        });
      } else {
        console.error("Invalid API response");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <Grid>
        <Typography variant="h6">Last 7 Days Revenue</Typography>
      </Grid>
      {isLoading ? (
        <CustomLoader />
      ) : chartData.series && chartData.series.length > 0 ? (
        <>
          <Chart
            options={chartData.options}
            series={chartData.series}
            type="bar"
            height={350}
          />
        </>
      ) : (
        <Typography>No data available.</Typography>
      )}
    </div>
  );
};
export default WeeklyRevenueChart;
