import React, { useCallback, useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  TextField,
  Button,
  Grid,
  IconButton,
  Typography,
  Tooltip,
  TablePagination,
  Autocomplete,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import ImageIcon from "@mui/icons-material/Image";
import { green, purple, red, teal } from "@mui/material/colors";
import { CustomLoader } from "../../../components/CustomLoader/CustomLoader";
import {
  getRequestForDownload,
  getRequestInstanceOne,
} from "../../../apiServices/apiCall";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import CustomDialog from "../../../components/CustomDialog/CustomDialog";
import GroupByDataExportExcel from "./GroupByDataExportExcel";
import { useDispatch, useSelector } from "react-redux";
import { updateSelectedFilterCategories, updateCustomDateRangeCategories } from "../../../feature/dateFilter/dateFilterSlice";

const StyledTableCell = styled(TableCell)(({ theme, isHeader }) => ({
  backgroundColor: isHeader ? "#d3d4ee" : "transparent",
  color: theme.palette.common.black,
  padding: "12px", // Adjust padding as needed
  textAlign: isHeader ? "center" : "left",
  position: "sticky",
  top: 0,
  zIndex: 1,
  fontWeight: 600, // Adjust font weight
  fontFamily: "Arial, sans-serif", // Adjust font family
  fontSize: "14px", // Adjust font size
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.background.default,
  },
  fontFamily: "Arial, sans-serif", // Font family for the table row
  fontSize: "14px", // Font size for the table row
  padding: "8px 12px", // Padding for the table row cells
  [theme.breakpoints.down("sm")]: {
    fontSize: "12px", // Adjust font size for mobile view
    padding: "6px 10px", // Adjust padding for mobile view
  },
}));

const TableWrapper = styled(Box)({
  width: "100%",
});

const TableContainerWrapper = styled(TableContainer)(({ theme }) => ({
  height: "350px",
  "& .MuiTableBody-root": {
    overflowY: "auto",
    height: "auto",
    minHeight: "calc(6 * 56px)", // Minimum height for 6 rows, assuming each row is 56px
  },
}));

export default function GroupByData({
  // customDateRange,
  groupBy,
  selectedGroup,
}) {
  const [groupByData, setGroupByData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogExcel, setOpenDialogExcel] = useState(false);
  const [openViewDialog, setOpenViewDialog] = useState(false);
  const [openViewDialogImg, setOpenViewDialogImg] = useState(false);
  const [viewData, setViewData] = useState([]);
  const [Stock_availableCount, setStock_availableCount] = useState(0);
  const [Store_closedCount, setStore_closedCount] = useState(0);
  const [Confirm_Count, setConfirm_Count] = useState(0);
  const [ONA_Count, setONA_Count] = useState(0);
  const [NoOrderCount, setNoOrderCount] = useState(0);
  const [image, setImage] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [totalRowCount, setTotalRowCount] = useState(0);
  const [rows, setRows] = useState([]);
  const [searchTermValue, setSearchTermValue] = useState("");
  const [debounceTimer, setDebounceTimer] = useState(null);

  const [startDate, setStartDate] = useState(() => {
    const today = new Date();
    return today.toISOString().split("T")[0]; // Format as YYYY-MM-DD
  });
  const [endDate, setEndDate] = useState(() => {
    const today = new Date();
    return today.toISOString().split("T")[0]; // Format as YYYY-MM-DD
  });
  const [count, setCount] = useState(0);
  const [tc, setTc] = useState(0);
  const [pc, setPc] = useState(0);

  const dispatch = useDispatch();
  const selectedFilter = useSelector((state) => state.categoriesDateFilter.selectedFilter);
  const customDateRange = useSelector((state) => state.categoriesDateFilter.customDateRange);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = event.target.value;
    setRowsPerPage(newRowsPerPage);
    setPage(0); // Reset page to 0 when rowsPerPage changes
    return newRowsPerPage;
  };

  const getOrderList = useCallback(async () => {
    setIsLoading(true);
    try {
      const params = new URLSearchParams({
        page: page + 1,
        per_page: rowsPerPage,
      });
      if (customDateRange) {
        params.append("start_date", customDateRange.start_date);
        params.append("end_date", customDateRange.end_date);
        params.append("report_type", groupBy);
      }
      if (selectedGroup) {
        params.append("value", selectedGroup);
      }
      if (searchTermValue) {
        params.append("query_string", searchTermValue.trim());
      }
      const url = `/api/group_by_accordion?${params.toString()}`;
      // const url = `/api/accordion/${groupBy}?${params.toString()}`;
      const result = await getRequestInstanceOne(url);

      const data = result.data.Order;
      // console.log("All --- Data: " + data);

      const restructureData = data.map((item, index) => {
        // Calculate total quantity for each order
        const totalQuantity = item.Quantity.flat()
          .map(Number)
          .reduce((acc, curr) => acc + curr, 0);

        // Return the restructured data object
        return {
          id: index,
          Beat_Name: item?.Beat_Name?.[0],
          Customer: item?.Customer,
          Date: item?.Date,
          Group: item?.Group,
          Location: item?.Location,
          OrderBy: item?.OrderBy,
          OrderID: item?.OrderID,
          Order_Status: item?.Order_Status,
          Order_Reason: item?.no_order_reason,
          Quantity: totalQuantity,
          PayerId: item?.PayerId,
          Stockist: item?.Stockist,
          image: item?.image,
          Status: item?.Status,
        };
      });

      setTc(result?.data.Count?.totalOrderCount);
      setPc(result?.data.Count?.orderCount);
      setConfirm_Count(result?.data.Count?.Confirm_Status);
      setNoOrderCount(result?.data.Count?.noOrderCount);
      setStock_availableCount(result?.data.Count?.Stock_Available);
      setStore_closedCount(result?.data.Count?.Store_Closed);
      setONA_Count(result?.data.Count?.ONA);

      setGroupByData(restructureData);
      // console.log("GroupByData", groupByData);
      setRows(restructureData);
      setTotalRowCount(result.data?.Count?.totalOrderCount);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, [
    selectedGroup,
    customDateRange,
    groupBy,
    page,
    rowsPerPage,
    searchTermValue,
  ]);

  useEffect(() => {
    getOrderList();
  }, [getOrderList]);

  const handleFilterSelect = (filter) => {
    dispatch(updateSelectedFilterCategories(filter));
    const today = new Date();
    let start_date, end_date;

    switch (filter) {
      case "Today":
        start_date = end_date = today.toISOString().split("T")[0];
        break;
      case "Weekly":
        start_date = new Date(today.setDate(today.getDate() - 7))
          .toISOString()
          .split("T")[0];
        end_date = new Date().toISOString().split("T")[0];
        break;
      case "Monthly":
        start_date = new Date(today.setMonth(today.getMonth() - 1))
          .toISOString()
          .split("T")[0];
        end_date = new Date().toISOString().split("T")[0];
        break;
      case "Yearly":
        start_date = new Date(today.setFullYear(today.getFullYear() - 1))
          .toISOString()
          .split("T")[0];
        end_date = new Date().toISOString().split("T")[0];
        break;
      case "Custom Range":
        setOpenDialog(true);
        return;
      default:
        return;
    }
    dispatch(updateCustomDateRangeCategories({ start_date, end_date }));
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);

    // Clear previous debounce timer
    if (debounceTimer) clearTimeout(debounceTimer);

    // Set new debounce timer
    const newTimer = setTimeout(() => {
      if (event.target.value.trim() !== "") {
        setSearchTermValue(event.target.value); // Trigger search when user stops typing
        setPage(0);
      } else {
        setSearchTermValue(""); // Trigger clear when input is empty
        setPage(0);
      }
      setIsLoading(true);
    }, 2000); // 2-second delay

    setDebounceTimer(newTimer);
  };

  const calculateCounts = (data) => {
    let Confirm_Count = 0;
    let Stock_availableCount = 0;
    let Store_closedCount = 0;

    data.forEach((item) => {
      if (item.Status == "confirm" && item.Quantity != 0) {
        Confirm_Count += 1;
      }

      if (
        (item.Order_Reason === "Stock Available " ||
          item.Order_Reason === "Stock Available") &&
        item.Quantity === 0
      ) {
        Stock_availableCount += 1;
      }

      if (item.Order_Reason == "Store Closed" && item.Quantity == 0) {
        Store_closedCount += 1;
      }
      if (item.Order_Reason == "ONA" && item.Quantity == 0) {
        ONA_Count += 1;
      }
    });
    return {
      Confirm_Count,
      Stock_availableCount,
      Store_closedCount,
    };
  };

  const filteredData = groupByData.filter((row) => {
    return Object.keys(row).some((key) => {
      if (key === "Date" || key === "Location") {
        return false; // Exclude Date and Location from search
      }
      return row[key]?.toString().toLowerCase();
      // .includes(debouncedSearchTerm.toLowerCase());
    });
  });

  useEffect(() => {
    const {} = calculateCounts(filteredData);
  }, [filteredData]);

  const handleDialogClose = () => {
    if (openDialog) {
      setOpenDialog(false);
      dispatch(updateSelectedFilterCategories(""));
    } else if(openDialogExcel) {
      setOpenDialogExcel(false);
    }
  };

  const handleCustomRangeSubmit = () => {
    const { start_date, end_date } = customDateRange;
    dispatch(updateSelectedFilterCategories(`${start_date} to ${end_date}`));
    setOpenDialog(false);
  };

  const handleDateChange = (field) => (event) => {
    dispatch(updateCustomDateRangeCategories({
      ...customDateRange,
      [field]: event.target.value,
    }));
  };

  const getOrderListViewItem = async (orderid) => {
    setIsLoading(true);
    try {
      const url = `/api/groupby_accordion_printview?orderid=${orderid}`;
      const result = await getRequestInstanceOne(url);
      const orderData = result?.data?.find((item) => item._id === orderid);

      if (orderData) {
        const data = orderData.itemid.map((item, index) => ({
          id: index,
          SKU: item,
          Price: orderData.price[index],
          Discount: orderData.discount[index],
          Quantity: orderData.quantity[index],
          OrderID: orderData._id,
          inputError: true,
        }));
        console.log("edit quantity view data", data);
        setOpenViewDialog(true);
        setViewData(data);
      } else {
        console.error("Quantity is 0");
        setOpenViewDialog(true);
        setViewData([]);
      }
    } catch (error) {
      console.error(error);
      setViewData([]);
    } finally {
      setIsLoading(false);
    }
  };

  const handleopenView = (params) => {
    const orderid = params?.OrderID; // Ensure orderid is correctly extracted
    if (orderid) {
      getOrderListViewItem(orderid);
    }
  };

  // Making text ellipsis... start

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };

  // Making text ellipsis... end

  const handleopenView_img1 = (params) => {
    setImage(params.image);
    setOpenViewDialogImg(true);
  };

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <CustomLoader open={isLoading} />
        <Box sx={{ mb: 2, display: "flex", justifyContent: "flex-end" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={2}>
              <TextField
                fullWidth
                size="small"
                type="search"
                placeholder={`Search`}
                value={searchTerm}
                onChange={handleSearch}
                sx={{
                  backgroundColor: "#fff",
                  borderRadius: "4px",
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "4px",
                    "& fieldset": {
                      borderColor: "#d3d4ee",
                    },
                    "&:hover fieldset": {
                      borderColor: "#a6a7c4",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#5052ba",
                    },
                  },
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={7}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Tooltip title={selectedGroup} placement="top" arrow>
                <Typography
                  variant="subtitle2"
                  sx={{
                    backgroundColor: "#cc9dff5e",
                    padding: "8px",
                    borderRadius: "4px",
                  }}
                >
                  {truncateText(selectedGroup || "-", 10)}
                </Typography>
              </Tooltip>

              <Typography
                variant="subtitle2"
                sx={{
                  backgroundColor: "#f4f6f8",
                  padding: "8px",
                  borderRadius: "4px",
                }}
              >{`TC: ${tc || 0}`}</Typography>

              <Typography
                variant="subtitle2"
                sx={{
                  backgroundColor: "#f4f6f8",
                  padding: "8px",
                  borderRadius: "4px",
                }}
              >{`PC: ${pc || 0}`}</Typography>

              <Typography
                variant="subtitle2"
                sx={{
                  backgroundColor: "#f4f6f8",
                  padding: "8px",
                  borderRadius: "4px",
                }}
              >
                {`Confirm: ${Confirm_Count || 0}`}
              </Typography>
              <Tooltip title="No Order" placement="top" arrow>
                <Typography
                  variant="subtitle2"
                  sx={{
                    backgroundColor: "#bbd8f5",
                    padding: "8px",
                    borderRadius: "4px",
                  }}
                >
                  {`No-O: ${NoOrderCount || 0}`}
                </Typography>
              </Tooltip>
              <Tooltip title="Stock Available" placement="top" arrow>
                <Typography
                  variant="subtitle2"
                  sx={{
                    backgroundColor: "#bbd8f5",
                    padding: "8px",
                    borderRadius: "4px",
                  }}
                >
                  {`Stock-A: ${Stock_availableCount || 0}`}
                </Typography>
              </Tooltip>
              <Tooltip title="Store Closed" placement="top" arrow>
                <Typography
                  variant="subtitle2"
                  sx={{
                    backgroundColor: "#bbd8f5",
                    padding: "8px",
                    borderRadius: "4px",
                  }}
                >
                  {`Store-C: ${Store_closedCount || 0}`}
                </Typography>
              </Tooltip>

              <Typography
                variant="subtitle2"
                sx={{
                  backgroundColor: "#bbd8f5",
                  padding: "8px",
                  borderRadius: "4px",
                }}
              >
                {`ONA: ${ONA_Count || 0}`}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={3}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <Grid
                item
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <Tooltip
                  title={`${customDateRange.start_date} ${customDateRange.end_date}`}
                  placement="top"
                  arrow
                >
                  <Autocomplete
                    disablePortal
                    size="small"
                    sx={{ bgcolor: "#fff", width: 150 }}
                    id="combo-box-demo"
                    value={selectedFilter}
                    options={[
                      "Today",
                      "Weekly",
                      "Monthly",
                      "Yearly",
                      "Custom Range",
                    ]}
                    getOptionLabel={(option) => option}
                    onChange={(event, value) => handleFilterSelect(value)}
                    renderInput={(params) => (
                      <TextField {...params} label="Date Filter" />
                    )}
                  />
                </Tooltip>
              </Grid>
              <Button
                size="small"
                variant="contained"
                onClick={() => setOpenDialogExcel(true)}
                sx={{
                  backgroundColor: "#5052ba",
                  color: "#fff",
                  "&:hover": {
                    backgroundColor: "#4042a3",
                  },
                  ml: 2,
                  p: 1,
                }}
                startIcon={<SaveAltIcon />}
              >
                Export Excel
              </Button>
            </Grid>
          </Grid>
        </Box>
        <TableWrapper>
          <TableContainerWrapper component={Paper} style={{ height: "90vh" }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {/* <StyledTableCell isHeader>OrderID</StyledTableCell> */}
                  <StyledTableCell isHeader sx={{ padding: "0 5px" }}>
                    OrderBy
                  </StyledTableCell>
                  {/* <StyledTableCell isHeader>PayerId</StyledTableCell> */}
                  <StyledTableCell isHeader>Stockist</StyledTableCell>
                  <StyledTableCell isHeader>Customer</StyledTableCell>
                  <StyledTableCell isHeader>Beat Name</StyledTableCell>
                  <StyledTableCell isHeader>Group</StyledTableCell>
                  <StyledTableCell isHeader>Date & Time</StyledTableCell>
                  <StyledTableCell isHeader>Quantity</StyledTableCell>
                  <StyledTableCell isHeader>Order_Status</StyledTableCell>
                  <StyledTableCell
                    isHeader
                    sx={{ position: "sticky", right: 70, zIndex: 2 }}
                  >
                    Location
                  </StyledTableCell>
                  <StyledTableCell
                    isHeader
                    sx={{ position: "sticky", right: 0, zIndex: 2 }}
                  >
                    Action
                  </StyledTableCell>
                  <StyledTableCell isHeader>Image</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredData.map((row) => (
                  <StyledTableRow key={row.id}>
                    {/* Order By Tooltip */}
                    <Tooltip title={row.OrderBy || "-"} placement="top" arrow>
                      <TableCell
                        align="center"
                        sx={{
                          padding: "0 5px",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          maxWidth: "150px",
                        }}
                      >
                        {truncateText(row.OrderBy || "-", 15)}
                      </TableCell>
                    </Tooltip>

                    {/* Payer Id and Stockist Tooltip */}
                    <Tooltip title={row.Stockist || "-"} placement="top" arrow>
                      <TableCell
                        align="center"
                        sx={{
                          padding: "0 5px",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          maxWidth: "150px",
                        }}
                      >
                        <span style={{ fontWeight: "bold" }}>
                          {truncateText(row.PayerId, 15)}
                        </span>{" "}
                        - {truncateText(row.Stockist || "-", 15)}
                      </TableCell>
                    </Tooltip>

                    {/* Customer Tooltip */}
                    <Tooltip title={row.Customer || "-"} placement="top" arrow>
                      <TableCell
                        align="center"
                        sx={{
                          padding: "0 5px",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          maxWidth: "150px",
                        }}
                      >
                        {truncateText(row.Customer || "-", 15)}
                      </TableCell>
                    </Tooltip>

                    {/* Beat Name Tooltip */}
                    <Tooltip title={row.Beat_Name || "-"} placement="top" arrow>
                      <TableCell
                        align="center"
                        sx={{
                          padding: "0 5px",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          maxWidth: "150px",
                        }}
                      >
                        {truncateText(row.Beat_Name || "-", 15)}
                      </TableCell>
                    </Tooltip>

                    {/* Group Tooltip */}
                    <Tooltip title={row.Group || "-"} placement="top" arrow>
                      <TableCell
                        align="center"
                        sx={{
                          padding: "0 5px",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          maxWidth: "150px",
                        }}
                      >
                        {truncateText(row.Group || "-", 15)}
                      </TableCell>
                    </Tooltip>

                    {/* Date Cell */}
                    <TableCell
                      align="center"
                      sx={{
                        padding: "0 5px",
                      }}
                    >
                      {row.Date || "-"}
                    </TableCell>

                    {/* Quantity Cell */}
                    <TableCell
                      align="center"
                      sx={{
                        padding: "0 5px",
                      }}
                    >
                      {row.Quantity || "0"}
                    </TableCell>

                    {/* Order Status / Reason Cell */}
                    <TableCell
                      align="center"
                      sx={{
                        padding: "0 5px",
                      }}
                    >
                      {row.Order_Status == null || row.Order_Status.length == 0
                        ? row.Status
                        : row.Order_Status}
                    </TableCell>

                    {/* Location Button */}
                    <TableCell
                      align="center"
                      sx={{
                        position: "sticky",
                        right: 70,
                        background: "white",
                        zIndex: 1,
                        padding: "0 5px",
                      }}
                    >
                      {row.Location &&
                      row.Location.Latitude &&
                      row.Location.Longitude ? (
                        <a
                          href={`https://www.google.com/maps?q=${row.Location.Longitude},${row.Location.Latitude}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <IconButton>
                            <LocationOnIcon />
                          </IconButton>
                        </a>
                      ) : (
                        "-"
                      )}
                    </TableCell>

                    {/* View Button */}
                    <TableCell
                      align="center"
                      sx={{
                        position: "sticky",
                        right: 0,
                        background: "white",
                        zIndex: 1,
                        padding: "0 5px",
                        cursor: row.Quantity === 0 ? "not-allowed" : "pointer",
                      }}
                    >
                      <Button
                        variant="outlined"
                        size="small"
                        color="success"
                        onClick={() => handleopenView(row)}
                        disabled={row.Quantity === 0}
                      >
                        View
                      </Button>
                    </TableCell>

                    {/* Image Button */}
                    <TableCell
                      align="center"
                      sx={{
                        padding: "0 5px",
                        textAlign: "center",
                        cursor: "not-allowed",
                      }}
                    >
                      <IconButton
                        disabled={row.image === null}
                        onClick={() => handleopenView_img1(row)}
                      >
                        <ImageIcon
                          sx={{
                            color: row.image !== null ? green[600] : green[100],
                          }}
                        />
                      </IconButton>
                    </TableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainerWrapper>
        </TableWrapper>
        <TablePagination
          component="div"
          count={totalRowCount || 0}
          page={page || 0}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage || 0}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <CustomDialog
          open={openDialogExcel}
          onClose={handleDialogClose}
          title={`Select Date Range`}
          fullscreen={false} // Set to true if you want the dialog to be fullscreen
          maxWidth="md" // Set the maxWidth as needed
        >
          <GroupByDataExportExcel
            setOpenDialogExcel={setOpenDialogExcel}
            customDateRange={customDateRange}
          />
        </CustomDialog>
        <CustomDialog
          open={openDialogExcel}
          onClose={handleDialogClose}
          title={`Select Date Range`}
          fullscreen={false} // Set to true if you want the dialog to be fullscreen
          maxWidth="md" // Set the maxWidth as needed
        >
          <GroupByDataExportExcel
            setOpenDialogExcel={setOpenDialogExcel}
            customDateRange={customDateRange}
          />
        </CustomDialog>
        <CustomDialog
          open={openViewDialog}
          onClose={() => setOpenViewDialog(false)}
          title={`View Quantity`}
          fullscreen={false} // Set to true if you want the dialog to be fullscreen
          maxWidth="md" // Set the maxWidth as needed
        >
          <TableWrapper>
            <TableContainerWrapper component={Paper}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell isHeader>SKU</StyledTableCell>
                    <StyledTableCell isHeader>Price</StyledTableCell>
                    <StyledTableCell isHeader>Discount</StyledTableCell>
                    <StyledTableCell isHeader>Quantity</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {viewData?.length > 0 ? (
                    viewData.map((view, index) => (
                      <StyledTableRow key={index}>
                        <TableCell align="center">{view?.SKU}</TableCell>
                        <TableCell align="center">{view?.Price}</TableCell>
                        <TableCell align="center">{view?.Discount}</TableCell>
                        <TableCell align="center">{view?.Quantity}</TableCell>
                      </StyledTableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={4} align="center">
                        Quantity is 0
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainerWrapper>
          </TableWrapper>
        </CustomDialog>
      </Box>
      {/* custome dialog start*/}
      <CustomDialog
        open={openViewDialogImg}
        onClose={() => {
          setOpenViewDialogImg(false);
        }}
        title={`View Image`}
        fullscreen={false} // Set to true if you want the dialog to be fullscreen
        maxWidth="md" // Set the maxWidth as needed
      >
        <div style={{ textAlign: "center", marginBottom: "10px" }}>
          <CustomLoader open={isLoading} />

          {/* Conditional rendering based on image availability */}
          {image && typeof image != "object" && image ? (
            <img src={image} alt="image" width={600} height={400} />
          ) : (
            <>
              <h2 style={{ color: "#ff0000a1" }}>No image found</h2>
            </>
          )}
        </div>
      </CustomDialog>

      {/* custome dialog end*/}

      <CustomDialog
        open={openDialog}
        onClose={handleDialogClose}
        title={`Select Date Range`}
        fullscreen={false} // Set to true if you want the dialog to be fullscreen
        maxWidth="sm" // Set the maxWidth as needed
      >
        <TextField
          margin="dense"
          label="Start Date"
          type="date"
          fullWidth
          value={customDateRange.start_date}
          onChange={handleDateChange("start_date")}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          margin="dense"
          label="End Date"
          type="date"
          fullWidth
          value={customDateRange.end_date}
          onChange={handleDateChange("end_date")}
          InputLabelProps={{
            shrink: true,
          }}
        />

        <Box display="flex" justifyContent="flex-end" mt={2}>
          <Button
            variant="outlined"
            color="error"
            onClick={handleDialogClose}
            style={{ marginRight: "8px" }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleCustomRangeSubmit}
            sx={{
              backgroundColor: "#5052ba",
              color: "#fff",
              "&:hover": {
                backgroundColor: "#4042a3",
              },
              ml: 2,
            }}
          >
            Submit
          </Button>
        </Box>
      </CustomDialog>
    </>
  );
}
