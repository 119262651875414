import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import {
  Autocomplete,
  Box,
  Button,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import CustomDialog from "../../../components/CustomDialog/CustomDialog";
import { getRequestInstanceOne } from "../../../apiServices/apiCall";
import { data, itemOption } from "./Data";
import ItemIdChartExportExcel from "./ItemIdChartExportExcel";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const ITEMS_PER_PAGE = 10;

const ItemIdChart = ({
  customDateRange,
  selectedSalesgroup,
  selectedStockist,
  selectedNames,
}) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [chartData, setChartData] = useState([]);
  const [itemId, setItemId] = useState([]);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [selectedItemIdView, setSelectedItemIdView] = useState(null);

  const fetchData = async () => {
    try {
      const params = new URLSearchParams();
      if (customDateRange) {
        params.append("start_date", customDateRange.start_date);
        params.append("end_date", customDateRange.end_date);
      }
      if (selectedSalesgroup) {
        params.append("salesgroup", selectedSalesgroup);
      }
      if (selectedStockist && localStorage.getItem("user") === "admin") {
        params.append("stockist", selectedStockist);
      }
      if (localStorage.getItem("user") !== "admin") {
        params.append("user", localStorage.getItem("user"));
      }
      if (selectedNames) {
        params.append("name", selectedNames);
      }
      const url = `/api/item_details?${params.toString()}`;
      const response = await getRequestInstanceOne(url);
      const formattedData = response.data.map((item) => ({
        item: item._id,
        value: item.unit === "Kg" ? item.total_kg_qty : item.total_pkt_qty,
        unit: item.unit,
      }));
      setChartData(formattedData);
    } catch (error) {
      console.error("Error fetching chart data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [customDateRange, selectedSalesgroup, selectedStockist, selectedNames]);

  const handleFilter = async (value) => {
    setSelectedItemId(value);
    setCurrentPage(0); // Reset to first page when filter changes

    try {
      const params = new URLSearchParams();
      if (customDateRange) {
        params.append("start_date", customDateRange.start_date);
        params.append("end_date", customDateRange.end_date);
      }
      if (value) {
        params.append("itemId", value);
      }
      const url = `/api/item_details?${params.toString()}`;
      const response = await getRequestInstanceOne(url);
      const formattedData = response.data.map((item) => ({
        item: item._id,
        value: item.unit === "Kg" ? item.total_kg_qty : item.total_pkt_qty,
        unit: item.unit,
      }));
      setChartData(formattedData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const totalPages = Math.ceil(chartData.length / ITEMS_PER_PAGE);
  const paginatedData = chartData.slice(
    currentPage * ITEMS_PER_PAGE,
    (currentPage + 1) * ITEMS_PER_PAGE
  );

  const fetchItemData = async () => {
    try {
      const params = new URLSearchParams();
      if (customDateRange) {
        params.append("start_date", customDateRange.start_date);
        params.append("end_date", customDateRange.end_date);
      }
      if (selectedSalesgroup) {
        params.append("salesgroup", selectedSalesgroup);
      }
      if (selectedStockist && localStorage.getItem("user") === "admin") {
        params.append("stockist", selectedStockist);
      }
      if (localStorage.getItem("user") !== "admin") {
        params.append("user", localStorage.getItem("user"));
      }
      if (selectedNames) {
        params.append("name", selectedNames);
      }
      const url = `/api/fetch_items?${params.toString()}`;
      const response = await getRequestInstanceOne(url);
      setItemId(response?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchItemData();
  }, [selectedSalesgroup, selectedStockist, selectedNames]);

  const options = {
    chart: {
      type: "bar",
      height: 350,
      zoom: {
        enabled: true,
      },
      events: {
        dataPointSelection: (event, chartContext, config) => {
          const selectedItem = paginatedData[config.dataPointIndex];
          setSelectedItemIdView(selectedItem.item);
          setOpenDialog(true);
        },
      },
    },
    fill: {
      colors: ["#1E3A8A"],
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    xaxis: {
      categories: paginatedData.map((item) => item.item),
      labels: {
        rotate: 0,
        style: {
          fontSize: "12px",
        },
      },
    },
    yaxis: {
      title: {
        text: "Items",
      },
    },
    tooltip: {
      y: {
        formatter: (value, { seriesIndex, dataPointIndex, w }) => {
          const unit = paginatedData[dataPointIndex].unit;
          return `${value} ${unit}`;
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    grid: {
      padding: {
        left: 10,
        right: 10,
      },
    },
    legend: {
      show: true,
      position: "top",
      horizontalAlign: "center",
      floating: true,
      fontSize: "14px",
      fontFamily: "Arial, sans-serif",
      fontWeight: 600,
      markers: {
        width: 12,
        height: 12,
        radius: 0,
      },
      itemMargin: {
        horizontal: 5,
        vertical: 10,
      },
    },
  };

  const series = [
    {
      name: "Value",
      data: paginatedData.map((item) => item.value),
    },
  ];

  return (
    <div id="chart">
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <Autocomplete
            fullWidth
            size="small"
            disablePortal
            id="combo-box-demo"
            value={selectedItemId}
            options={itemId}
            getOptionLabel={(option) => option}
            onChange={(event, value) => handleFilter(value)} // Update selectedItemIds on change
            renderInput={(params) => <TextField {...params} label="Item IDs" />}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          {/* <Typography variant="h6" align="center">ItemId</Typography> */}
        </Grid>
      </Grid>
      <div>
        {series &&
        series.length > 0 &&
        series.some((s) => s.data.length > 0) ? (
          <Chart options={options} series={series} type="bar" height={370} />
        ) : (
          <div
            style={{
              height: "370px", // Ensure the height matches the chart
              display: "flex", // Center the message
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              fontSize: "16px",
            }}
          >
            No data is available
          </div>
        )}
      </div>

      <Grid item xs={12}>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Button
            variant="contained"
            onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 0))}
            disabled={currentPage === 0}
            sx={{
              mr: 2,
              pr: 0.2,
              backgroundColor: "#d3d4ee",
              color: "#5052ba",
              "&:hover": {
                backgroundColor: "#d3d4ee", // Optional: lighter shade for hover effect
              },
            }}
          >
            <ArrowBackIosIcon />
          </Button>
          <Button
            variant="contained"
            onClick={() =>
              setCurrentPage((prev) => Math.min(prev + 1, totalPages - 1))
            }
            disabled={currentPage === totalPages - 1}
            sx={{
              backgroundColor: "#d3d4ee",
              color: "#5052ba",
              "&:hover": {
                backgroundColor: "#d3d4ee", // Optional: lighter shade for hover effect
              },
            }}
          >
            <ArrowForwardIosIcon />
          </Button>
        </Box>
      </Grid>
      <CustomDialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        title={`View Quantity - ${selectedItemIdView}`}
        fullscreen={true}
        maxWidth="xxl"
      >
        <ItemIdChartExportExcel
          key={
            customDateRange.start_date +
            customDateRange.end_date +
            selectedSalesgroup +
            selectedStockist +
            selectedNames
          }
          customDateRange={customDateRange}
          selectedItemIdView={selectedItemIdView}
          setOpenDialog={setOpenDialog}
          selectedSalesgroup={selectedSalesgroup}
          selectedStockist={selectedStockist}
          selectedNames={selectedNames}
        />
      </CustomDialog>
    </div>
  );
};

export default ItemIdChart;
